.card-keuangan {
    padding: 16px;
    margin-bottom: 4rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: left;
    background: white;
}

.card-keuangan img {
    width: 100%;
    height: 350px;
    object-fit: "cover";
    margin-bottom: 16px;
}

.card-keuangan a {
    color: #0060ff;
    text-decoration: underline;
}

.card-keuangan a,
.card-keuangan a:active,
.card-keuangan a:focus,
.card-keuangan a:hover {
    outline: none;
    text-decoration: none;
    color: #002147;
}

.card-keuangan a:hover {
    color: #0060ff;
}

.card-keuangan a {
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
}

.header-keuangan {
    width: 100%;
    background-color: #f5f5f5;
    margin-bottom: 2rem;
    font-size: 0.9rem;
}

.header-keuangan ul {
    margin: 0;
    padding: 10px 25px;
    list-style: none;
}

.header-keuangan ul>li{
    display: inline-block;
}

.header-keuangan ul li,
.header-keuangan ul li a {
    font-weight: 500;
    color: #002147;
    text-decoration: none;
}

.header-keuangan ul li:nth-child(2) {
    margin-left: 0.75rem;
}

.header-keuangan ul li:nth-child(3) {
    margin-left: 0.75rem;
}

.content-keuangan {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical;
}