.sambutan-container {
    margin-bottom: 2rem;
}

.parent-sambutan {
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    text-align: center;
}

.sambutan-title {
    margin-bottom: 1rem;
    color: #333;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.sambutan-image {
    margin-bottom: 1rem;
}

.sambutan-image img {
    width: 100%;
    max-width: 220px;
    height: auto;
    border-radius: 10px;
}

.sambutan-text {
    font-size: 15px;
    line-height: 1.8;
    color: #555;
    margin-bottom: 1rem;
    font-family: 'Poppins', sans-serif;
    text-align: justify;
}

.sambutan-footer {
    margin-top: 2rem;
    font-size: 16px;    
    color: #333;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: left;
}

.nip-text {
    font-size: 14px;
    font-weight: normal;
    color: #777;
}

@media screen and (max-width: 992px) {
    .parent-sambutan {
        padding: 1.5rem;
    }
}

@media screen and (max-width: 767px) {
    .parent-sambutan {
        padding: 1rem;
    }
}

@media screen and (max-width: 575px) {
    .parent-sambutan {
        padding: 0.5rem;
    }
}
