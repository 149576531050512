.card-style {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
    text-align: left;
    padding: 20px;
    margin-bottom: 2.5rem;
    background-color: #fff;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-style {
    width: 400px;
    height: 250px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 20px;
}

.content-style {
    padding: 20px 50px;
    margin-top: 30px;
}

.section-style {
    margin-bottom: 40px;
    text-align: center;
    padding: 20px 50px;
}

.contact-section-style {
    padding: 20px 50px;
    text-align: center;
    margin-bottom: 40px;
}

.ekstrakurikuler-container-style {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}
.ekstrakurikuler-container-style .icon-ekstrakurikuler {
    color: white;
    font-size: 3rem;
}

.banner-beranda {
    transform: translateY(scrollY * 0.5px);
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden;
  };


.banner-beranda::after{
    background: #000000 none repeat scroll 0 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    content: "";
    height: 30%;
    left: 0;
    opacity: 0.6;
    position: absolute;
    top:4rem;
    width: 100%;
    z-index: -1;
}

.containerr {
    max-width: 800px;
    margin: 20px auto;
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
}

.card-small {
    display: flex;
    align-items: flex-start;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 15px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-small:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.image-small {
    width: 100px;
    height: 70px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 15px;
}

.content-small {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.content-small h4 {
    margin: 0 0 10px;
    color: #333;
    font-size: 1rem;
}

.content-small .date,
.content-small p {
    margin: 0;
    font-size: 0.875rem;
    color: #666;
}

.content-small .description {
    margin-top: 8px;
    color: #444;
    font-size: 0.875rem;
    line-height: 1.4;
}

@media screen and (max-width: 575px) {
    .card-small {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .image-small {
        margin: 0 0 10px 0;
    }

    .content-small {
        align-items: center;
    }
}


@media screen and (max-width: 992px) {
    .card-style {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        padding: 10px;
    }

    .content-style {
        padding: 10px 30px;
        margin-top: 20px;
    }

    .section-style {
        padding: 20px 40px;
    }

    .contact-section-style {
        padding: 20px 40px;
    }

    .ekstrakurikuler-container-style {
        grid-template-columns: repeat(2, 1fr);
        gap:10px;
    }
}

@media screen and (max-width: 575px) {
    .content-style {
        padding: 10px 20px;
        margin-top: 20px;
    }

    .section-style {
        padding: 10px 20px;
    }

    .contact-section-style {
        padding: 10px 20px;
    }

    .ekstrakurikuler-container-style {
        grid-template-columns: repeat(1, 1fr);
    }
}

.project-area {
    background-color: #f9f9f9;
    padding: 30px 0;
}

.carousel {
    max-width: 100%; 
    width: 80%; 
    height: 400px; 
    margin: 0 auto;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); 
}

.carousel-inner {
    border-radius: 15px;
    overflow: hidden;
    height: 100%; 
}

.carousel-img {
    height: 100%;  
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 15px;
    transition: transform 0.3s ease-in-out;
}

.carousel-img:hover {
    transform: scale(1.05);
}

.carousel-indicators li {
    background-color: #333;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    filter: invert(100%);
}

.carousel-control-prev,
.carousel-control-next {
    width: 5%;
}

@media screen and (max-width: 992px) {
    .carousel {
        width: 90%; 
        height: 350px; 
    }
}

@media screen and (max-width: 575px) {
    .carousel {
        width: 100%;
        height: 300px; 
    }
}
