.image-style {
    transform: translateY(scrollY * 0.5px);
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.image-style img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Ensures the image covers the container */
}

.text-overlay-style {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
}

.header-prestasi {
    width: 100%;
    margin-bottom: 2rem;
    padding: 4px 2rem 0 0;
}

.header-prestasi::after {
    background: #000000 none repeat scroll 0 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    content: "";
    height: 30%;
    left: 0;
    opacity: 0.6;
    position: absolute;
    top: 5.5rem;
    width: 100%;
    z-index: -1;
}

.header-prestasi ul {
    list-style: none;
    padding: 10px 15px;
}

.header-prestasi ul li {
    font-size: 1.2rem;
    text-transform: uppercase;
    display: inline-block;
}

.header-prestasi ul li i {
    color: #0060ff;
}

.header-prestasi ul li,
.header-prestasi ul li a {
    font-weight: 600;
    color: white;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
}

.header-prestasi ul li:nth-child(2) {
    margin-left: 1rem;
}

.header-prestasi ul li:nth-child(3) {
    margin-left: 1rem;
}

.container-prestasi img {
    transform: translateY(scrollY * 0.5px);
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
}

@media screen and (max-width: 992px) {
    .header-prestasi ul li {
        font-size: 1rem;
        text-transform: uppercase;
    }

    .header-prestasi ul li:nth-child(2) {
        margin-left: 0.5rem;
    }

    .header-prestasi ul li:nth-child(3) {
        margin-left: 0.5rem;
    }

    .header-prestasi::after {
        background: #000000 none repeat scroll 0 0;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        content: "";
        height: 50%;
        left: 0;
        opacity: 0.6;
        position: absolute;
        top: 9rem;
        width: 100%;
        z-index: -1;
    }

    .container-prestasi {
        padding-top: 20px;
        margin-top: 0;
    }
}
