/* gallery.css */
*{
  font-size: 100%;
}

.galeri-container {
  padding: 20px 120px;
  margin-top: 100px;
  margin-bottom: 2rem;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 1rem;
  justify-content: center;
}

.image-card {
  width: 100%;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.image-card img {
  width: 100%;
  height: 200px;
  border-radius: 8px;
}

.image-card:hover {
  transform: scale(1.05);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  max-width: 60%;
  max-height: 90%;
  overflow: auto;
  position: relative;
  text-align: center;
}

.modal-image {
  width: auto;
  height: 52vh;
  max-width: 100%;
  max-height: 60vh;
  border-radius: 8px;
  margin-bottom: 20px;
}

.close-button {
  background-color: #0060ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.close-button:hover {
  background-color: #004bbd;
}
.header-galeri {
  width: 100%;
  background-color: #f5f5f5;
  margin-bottom: 2rem;
  font-size: 0.9rem;
}

.header-galeri ul {
  padding: 10px 15px;
  list-style: none;
  display: flex;
}

.header-galeri ul li,
.header-galeri ul li a {
  font-weight: 500;
  color: #002147;
  text-decoration: none;
}

.header-galeri ul li:nth-child(2) {
  margin-left: 0.75rem;
}


@media screen and (max-width: 992px) {
  *{
    font-size: 98%;
  }
  
  .gallery-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .galeri-container {
    padding: 0 20px;
    padding-top: 110px;
    margin-top: 0;
    gap: 0;
  }
}

@media all and (max-width: 575px) {
  *{
    font-size: 96%;
  }
  
  .gallery-container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}