.my-custom-details {
  color: black;
  height: 500px;
}

.pembungkus-gabung {
  padding-top: 100px;
}

.media-social {
  display: flex;
  gap: 100px;
}

.btn-kembali {
  width: 100px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.btn-kembali-berkala {
  width: 100px;
}

.widget_news .details.card-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
}

.widget_news .details.card-container .card {
  display: flex;
  flex-direction: column;
  background: none;
  box-shadow: none;
  border: none;
  margin: 0;
  padding: 0;
}

.widget_news .details.card-container .card-body {
  padding: 0.5rem;
  text-align: left;
  background-color: #f5f5f5;
}

.widget_news .details.card-container .card-title {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Limit to one line */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Ensure text wraps within the container */
  font-size: 1rem;
  margin: 0 0 8px;
  color: #002147;
  text-decoration: none;
  text-align: left;
}

.widget_news .details.card-container .card-title:hover {
  text-decoration: none;
}

.widget_news .details.card-container .card-date {
  font-size: 0.75rem;
  color: #4c4848;
  margin: 0 0 8px;
  text-align: left;
}

.widget_news .details.card-container .card-content {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Adjust as needed for more lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 0.875rem;
  color: #fff;
  text-align: left;
}

@media (min-width: 992px) {
  .logo-container {
    margin-left: 100px;
    margin-right: 50px;
  }
}

@media (max-width: 991px) {
  .logo-container {
    margin-left: 0;
    text-align: center;
    width: 100%;
  }
}

@media screen and (max-width:1440) {
}

@media (min-width: 992px) {
}

@media (max-width: 992px) {
}

@media screen and (max-width:768px) {

  #strukturOrganisasi {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .pembungkus-gabung {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .text1-gabung {
    text-align: center;
  }

  .text2-gabung {
    text-align: center;
  }

  #thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-left: 100px;
    margin-left: 50px;
  }

  #icon-text-waktu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  #icon-waktu1 {
    padding-right: 120px;
  }

  #icon-waktu2 {
    padding-right: 75px;
  }

  #icon-waktu3 {
    padding-right: 110px;
  }

  #icon-waktu4 {
    padding-right: 5px;
  }
}

@media screen and (max-width: 320px) {

  #strukturOrganisasi {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  #text1-gabung {
    font-size: 15px;
    text-align: center;

  }

  #text2-gabung {
    font-size: 20px;
    text-align: center;
  }

  .div-gabung {
    text-align: center;

  }

  .pembungkus-gabung {
    padding-right: 50px;
  }

  .keterangan-padding {
    padding: 10px;
    text-align: justify;
  }

  #div-gabung-akhir {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}

@media (max-width: 390px) {
  .posisi {
    position: relative;

  }

  #strukturOrganisasi {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .form-control.widget-content-right {
    width: 100%;
    margin: 10px 0;
  }

  .form-control.widget-content-right.w-75 {
    margin-left: auto;
    margin-right: auto;
  }

  #icon-waktu1 {
    padding-right: 90px;
  }

  #icon-waktu2 {
    padding-right: 45px;
  }

  #icon-waktu3 {
    padding-right: 80px;
  }

  #icon-waktu4 {
    padding-left: 30px;
  }
}