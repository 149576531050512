body {
    background: #F8F8F8;
}

/* ============================================================
      Responsive Table via Data Label
  ============================================================ */
table {
    border: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
    border-spacing: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .3);

    thead {
        background: #F0F0F0;
        height: 60px !important;

        tr {
            th {
                text-transform: uppercase;
                line-height: 60px !important;
                text-align: center;
                font-size: 11px;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
            }
        }
    }


    tbody {
        background: #fff;

        tr {
            border-top: 1px solid #e5e5e5;
            height: 60px;

            td {
                text-align: left;
            }

            /* td{
          height: 60px;
          line-height: 60px!important;
                  text-align: left;
                  padding:0 10px;
                  font-size:14px;

                  i{
                      margin-right:8px;
                  }
              } */
        }
    }
}

@media screen and (max-width: 800px) {
    table {
        border: 1px solid transparent;
        box-shadow: none;

        thead {
            display: none;
            tr {
                th{
                    text-align: left;
                }
            }
        }

        tbody {
            tr {
                border-bottom: 45px solid #F8F8F8;

                td:before {
                    content: attr(data-label);
                    float: left;
                    font-size: 10px;
                    text-transform: uppercase;
                    font-weight: bold;
                }

                td {
                    display: block;
                    text-align: right;
                    font-size: 14px;
                    padding: 0px 10px;
                    box-shadow: 0 1px 1px rgba(0, 0, 0, .3);
                }

                td.action{
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }

    .isi_data_tabel {
        text-align: right;
    }
}