@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

body {
    font-family: 'Poppins', sans-serif;
}

.container-berita {
    padding: 20px 120px;
    margin-top: 100px;
}

.container-apbd {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 2rem;
}

.container-all {
    grid-column: span 2 / span 2;
}

.search-box {
    display: flex;
    width: 100%;
}

.search-box input {
    background-color: white;
    border: 1px solid #ddd;
    width: 100%;
    padding: 0.75rem;
}

.search-box button {
    background-color: #0060ff;
    color: white;
    border: none;
    padding: 0.2rem 1rem;
    font-weight: 600;
    font-size: 1.1em;
}

.category-berita {
    list-style: none;
}

.category-berita li a {
    font-weight: 600;
    color: #002147;
    text-decoration: none;
}

.medsos-list {
    list-style: none;
    display: flex;
    gap: 0.5rem;
}

.medsos-list li {
    padding: 20px 15px;
}

.medsos-list li:nth-child(1) {
    background-color: #3b5998;
}

.medsos-list li:nth-child(2) {
    background-color: #DD2A7B;
}

.medsos-list li:nth-child(3) {
    background-color: #f80606;
    padding: 20px 12px;
}

.medsos-list li a {
    color: white;
    text-decoration: none;
}

.container-detail-berita {
    padding: 20px 0;
    margin-top: 100px;
    margin-bottom: 2rem;
}

.container-detail-berita img {
    transform: translateY(scrollY * 0.5px);
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
}

.container-detail-berita a {
    text-decoration: none;
    color: #002147;
    font-weight: 600;
}

.container-detail-berita a:hover {
    color: #0060ff;
}

.container-detail-berita p {
    text-align: left;
}

.container-detail-kegiatan {
    margin-bottom: 2rem;
}

.container-detail-kegiatan img {
    transform: translateY(scrollY * 0.5px);
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
}

.container-detail-kegiatan a {
    text-decoration: none;
    color: #002147;
    font-weight: 600;
}

.container-detail-kegiatan a:hover {
    color: #0060ff;
}

.container-detail-kegiatan p {
    text-align: left;
}

@media screen and (max-width: 992px) {
    .container-berita {
        padding: 0 20px;
        padding-top: 110px;
        margin-top: 0;
        gap: 0;
    }

    .container-detail-berita {
        padding: 0 20px;
        padding-top: 80px;
        margin-top: 0;
    }

    .container-detail-kegiatan {
        padding: 0 20px;
        padding-top: 80px;
        margin-top: 0;
    }

    .container-apbd {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}