/*
Theme Name: Iitechie
Theme URI: https://solverwp.com/demo/html/edumint/
Author: Themeled
Author URI: https://solverwp.com/demo/html/edumint/
Description: Iitechie - IT Solutions and Services Html Template
Version: 1.0.5
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Global
    ## Global
    ## Mixins
    ## Theme
    ## Variables
    ## Common

# Sections
    ## Nav Bar
    ## Banner
    ## Brand Area
    ## Footer
    ## Widgets

# Elements
    ## accordion
    ## filter
    ## apartment
    ## blog
    ## city
    ## feature
    ## gallery
    ## intro
    ## main-search
    ## pricing
    ## service
    ## team
    ## user-list

--------------------------------------------------------------*/
/*-----------------
    # Typography
-----------------*/
@import url("https://fonts.googleapis.com/css?family=Rajdhani:400,500,600,700|Poppins:400,500,600,700|Rubik:400,500,600,700|family=Inter:wght@300;400;500;600;700;800&family=Roboto:wght@300;400;500;700&display=swap");

:root {
  --main-color: #0d2f74;
  --main-color3: #30c47a;
  --main-color2: #EE0020;
  --heading-color: #151423;
  --paragraph-color: #616161;
  --body-font: "Rubik", sans-serif;
  --heading-font: "Rajdhani", sans-serif;
  --body-font-size: 16px;
  --line-height30: 1.7;
}
a {
  text-decoration: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  overflow-x: hidden;
  /* font-family: var(--body-font); */
  font-family: "Poppins", serif;
  font-weight: 100;
  font-style: normal;

}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
  font-family: "Poppins", serif;
  /* font-weight: 400; */
  font-style: normal;
  text-decoration: none;
}

h2 {
  font-family: "Poppins", serif;
}

body {
  margin: 0;
  color: var(--paragraph-color);
  overflow-x: hidden;
  /* font-family: var(--body-font); */
  line-height: var(--line-height30);
  font-size: var(--body-font-size);
  background: #fff;
  text-decoration: none;
  font-family: "Poppins", serif;
  font-weight: 100;
  font-style: normal;

}

h1 {
  font-size: 75px;
  line-height: 1.2333333333;
}

h2 {
  font-size: 40px;
  line-height: 1.2380952381;
}

h3 {
  font-size: 30px;
  line-height: 1.3833333333;
}

h4 {
  font-size: 24px;
  line-height: 1.3380952381;
}

h5 {
  font-size: 20px;
  line-height: 1.3380952381;
}

h6 {
  font-size: 16px;
  line-height: 1.2380952381;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-weight: 600;
  font-family: var(--heading-font);
}

p {
  color: var(--paragraph-color);
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  margin-bottom: 10px;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  color: inherit;
}

a:hover {
  color: var(--main-color);
}

pre {
  word-break: break-word;
}

a i {
  padding: 0 2px;
}

img {
  max-width: 100%;
}

button:hover,
button:active,
button:focus {
  outline: 0;
}

/*input and button type focus outline disable*/
input[type=text]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=number]:focus,
textarea:focus,
input[type=button]:focus,
input[type=reset]:focus,
input[type=submit]:focus,
select:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ddd;
}

.no-gutter.row,
.no-gutter.container,
.no-gutter.container-fluid {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.no-gutter>[class^=col-] {
  padding-left: 0;
  padding-right: 0;
}

.no-gutter[class^=col-] {
  padding-left: 0;
  padding-right: 0;
}

.h-100vh {
  height: 100vh;
}

code {
  color: #faa603;
}

.check-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.check-list li {
  display: block;
  padding-left: 20px;
  position: relative;
  z-index: 0;
}

.check-list li:after {
  position: absolute;
  left: 0;
  top: 0;
  font-family: "fontawesome";
  content: "\f105";
  color: var(--main-color);
}

.site-main .comment-navigation,
.site-main .posts-navigation,
.site-main .post-navigation {
  clear: both;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
  float: left;
  width: 50%;
}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
  float: right;
  text-align: right;
  width: 50%;
}

.comment-navigation .nav-previous>a,
.posts-navigation .nav-previous>a,
.post-navigation .nav-previous>a,
.comment-navigation .nav-next>a,
.posts-navigation .nav-next>a,
.post-navigation .nav-next>a {
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.comment-navigation .nav-previous:hover>a,
.posts-navigation .nav-previous:hover>a,
.post-navigation .nav-previous:hover>a,
.comment-navigation .nav-next:hover>a,
.posts-navigation .nav-next:hover>a,
.post-navigation .nav-next:hover>a {
  color: var(--main-color);
}

.comment-list li {
  list-style: none;
}

.h-100vh {
  height: 100vh;
}

.position-relative {
  position: relative;
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  -webkit-clip-path: none;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 15px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  float: left;
  clear: both;
  margin-right: 20px;
}

.alignright {
  float: right;
  clear: both;
  margin-left: 20px;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 1.75em;
}

.alignfull {
  margin: 1.5em 0;
  max-width: 100%;
}

.alignwide {
  max-width: 1100px;
}

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
  content: "";
  display: table;
  table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
  clear: both;
}

/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/
.sticky {
  display: block;
}

.updated:not(.published) {
  display: none;
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
  display: inline-block;
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
  clear: both;
}

.wp-caption img[class*=wp-image-] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

.wp-caption-text {
  text-align: center;
}

/*----------------------------------------
    # Unit test
------------------------------------------*/
.wp-link-pages a {
  margin: 0 5px;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.wp-link-pages {
  margin-bottom: 30px;
  margin-top: 25px;
}

.wp-link-pages span,
.wp-link-pages a {
  border: 1px solid #e2e2e2;
  padding: 5px 15px;
  display: inline-block;
}

.wp-link-pages .current,
.wp-link-pages a:hover {
  background-color: var(--main-color);
  color: #fff;
  border-color: var(--main-color);
}

.wp-link-pages span:first-child {
  margin-right: 5px;
}

dl,
ol,
ul {
  padding-left: 17px;
}

.post-password-form input {
  display: block;
  border: 1px solid #e2e2e2;
  height: 50px;
  border-radius: 3px;
  padding: 0 20px;
}

.post-password-form label {
  font-weight: 600;
  color: #333;
}

.post-password-form input[type=submit] {
  width: 100px;
  height: 50px;
  background-color: var(--main-color);
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.post-password-form input[type=submit]:hover {
  background-color: #121A2F;
}

.footer-widget .table td,
.footer-widget .table th {
  padding: 0.5rem !important;
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.custom-gutters-10>.col,
.custom-gutters-10>[class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1170px;
  }
}

@media all and (min-width: 1200px) {

  .no-lg-gutters>.col,
  .no-lg-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0;
  }
}

/*---------------------------------------
    ## Button
---------------------------------------*/
.btn {
  height: 55px;
  line-height: 55px;
  padding: 0 30px;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  border: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  font-weight: 500;
  border-radius: 4px;
  z-index: 0;
}

.btn:focus,
.btn:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn:after {
  content: "";
  background: #232237;
  position: absolute;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  z-index: -1;
  height: 100%;
  left: -35%;
  top: 0;
  -webkit-transform: skew(30deg);
  transform: skew(30deg);
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  width: 0;
}

.btn:hover:after {
  height: 100%;
  width: 135%;
}

.btn i {
  font-size: 21px;
  float: right;
  padding-top: 16px;
  padding-left: 5px;
}

.btn .fa-paper-plane {
  font-size: 16px;
  padding-left: 8px;
}

.btn-small {
  height: 48px;
  line-height: 48px;
}

.btn-small i {
  padding-top: 12px;
}

.btn-base {
  color: #fff;
  background: var(--main-color);
}

.btn-base:hover {
  color: #fff;
}

.btn-light-base {
  color: #616161;
  background: #F4F8FF;
}

.btn-light-base:hover {
  color: #fff;
}

.btn-black {
  color: #fff;
  background: var(--heading-color);
}

.btn-black:hover {
  color: #fff;
}

.btn-white {
  color: var(--main-color) !important;
  background: #fff;
}

.btn-white:hover,
.btn-white:focus {
  color: #fff !important;
  background: var(--main-color);
}

.btn-border-base {
  color: var(--main-color);
  border: 2px solid var(--main-color);
  line-height: 53px;
}

.btn-border-base:hover,
.btn-border-base:focus {
  color: #fff;
}

.btn-border-white {
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.7);
  line-height: 53px;
}

.btn-border-white:hover,
.btn-border-white:focus {
  color: #fff;
  background: var(--main-color);
  border: 2px solid var(--main-color);
}

.btn-border-white.btn-small {
  line-height: 45px;
}

.btn-border-black {
  color: #a2a2a2;
  border: 2px solid #d4d4d4;
  line-height: 53px;
}

.btn-border-black:hover,
.btn-border-black:focus {
  color: #fff;
  background: var(--main-color);
  border: 2px solid var(--main-color);
}

.btn-border-black:after {
  background: var(--main-color);
}

.btn-border-black-2 {
  color: #262626;
  border: 2px solid #262626;
  line-height: 53px;
}

.btn-border-black-2:hover,
.btn-border-black-2:focus {
  color: #fff;
  background: var(--main-color);
  border: 2px solid var(--main-color);
}

.btn-border-black-2:after {
  background: var(--main-color);
}

.read-more-text {
  position: relative;
}

.read-more-text i {
  font-size: 18px;
  position: absolute;
  top: 1px;
  margin-left: 3px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.read-more-text svg {
  font-size: 18px;
  position: absolute;
  top: 1px;
  margin-left: 6px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.read-more-text:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: var(--main-color);
  height: 1px;
  width: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  visibility: hidden;
  opacity: 0;
}

.read-more-text:hover i {
  padding-left: 6px;
}

.read-more-text:hover:after {
  width: 100%;
  visibility: visible;
  opacity: 1;
}

.read-more-arrow {
  height: 35px;
  width: 35px;
  line-height: 35px;
  color: #fff;
  text-align: center;
  background: var(--main-color);
  border-radius: 50%;
  display: inline-block;
}

.read-more-arrow:hover {
  background: #0A1851;
  color: #fff;
}

/*------------------------------------------------
    ## Back Top
------------------------------------------------*/
.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 44px;
  height: 44px;
  color: #fff;
  background-color: var(--main-color);
  text-align: center;
  line-height: 44px;
  z-index: 99;
  font-size: 25px;
  cursor: pointer;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: none;
  -webkit-animation: backto-top-bounce 4s infinite ease-in-out;
  animation: backto-top-bounce 4s infinite ease-in-out;
}

@-webkit-keyframes backto-top-bounce {
  0% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }

  50% {
    -webkit-transform: translateY(8px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
}

@keyframes backto-top-bounce {
  0% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }

  50% {
    -webkit-transform: translateY(8px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
}

/*-----------------------------------------
    ## Preloader Css
-------------------------------------------*/
.pre-wrap {
  position: fixed;
  content: "";
  -webkit-transform: translate(-100%, -240%);
  transform: translate(-100%, -240%);
  font-size: 62px;
}

.preloader-inner {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999999999;
  background-color: #030724;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.preloader-inner .cancel-preloader {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.preloader-inner .cancel-preloader a {
  background-color: #fff;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--main-color);
  width: 200px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 30px;
  display: block;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.preloader-inner .cancel-preloader a:hover {
  background-color: var(--heading-color);
  color: #fff;
}

.spinner {
  margin: 120px auto;
  width: 60px;
  height: 60px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: var(--main-color);
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {

  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {

  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/*-----------------------------------------
    ## video
------------------------------------------*/
.video-thumb {
  position: relative;
}

.video-thumb .video-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.video-play-btn {
  border-radius: 50%;
  background: rgb(247, 247, 247);
  width: 80px;
  height: 80px;
  display: inline-block;
  line-height: 82px;
  position: relative;
  z-index: 0;
  text-align: center;
  -webkit-animation: ripple-white3 2.5s linear infinite;
  animation: ripple-white3 2.5s linear infinite;
  z-index: 3;
}

.video-play-btn:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  top: -15px;
  left: -15px;
}

.video-play-btn i {
  color: var(--main-color);
  margin-left: 6px;
  font-size: 28px;
}

.video-play-btn.video-play-btn-base {
  background: var(--main-color);
}

.video-play-btn.video-play-btn-base svg {
  color: #fff;
}

.video-play-btn.video-play-btn-base:after {
  display: none;
}

.video-play-btn.small {
  width: 55px;
  height: 55px;
  line-height: 60px;
}

.video-play-btn.small i {
  font-size: 18px;
}

/*-----------------------------------------
    ## Breadcumb
------------------------------------------*/
.breadcrumb-area {
  padding: 205px 0 115px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.breadcrumb-area .banner-bg-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background-size: 102% 96%;
}

.breadcrumb-area .breadcrumb-inner {
  position: relative;
  z-index: 2;
}

.breadcrumb-area .page-title {
  font-size: 75px;
  font-weight: 700;
  margin-bottom: 5px;
  color: #fff;
}

.breadcrumb-area .page-list {
  margin: 0;
  padding: 0;
}

.breadcrumb-area .page-list li {
  font-size: 18px;
  font-weight: 500;
  list-style: none;
  display: inline-block;
  position: relative;
  padding-left: 8px;
  color: #fff;
}

.breadcrumb-area .page-list li:after {
  position: absolute;
  left: 0;
  top: 1px;
  content: "/";
  font-family: "fontawesome";
}

.breadcrumb-area .page-list li:first-child {
  padding-left: 0;
}

.breadcrumb-area .page-list li:first-child:after {
  display: none;
}

/********* social-media *********/
.social-media {
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 2;
}

.social-media li {
  display: inline-block;
  margin-right: 0 !important;
  margin: 0 2px;
}

.social-media li a {
  height: 28px;
  width: 28px;
  line-height: 29px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  background: #fff;
  color: var(--main-color);
  font-size: 12px;
}

.social-media li a:hover {
  background: #fff;
  color: var(--main-color);
}

.social-media li a.facebook {
  color: #3b5999;
  border-color: #3b5999;
}

.social-media li a.twitter {
  color: #55acee;
  border-color: #55acee;
}

.social-media li a.instagram {
  color: #e4405f;
  border-color: #e4405f;
}

.social-media li a.youtube {
  color: #cd201f;
  border-color: #cd201f;
}

.social-media li:first-child {
  margin-left: 0;
}

.social-media li:last-child {
  margin-right: 0;
}

.social-media-light a {
  background: var(--heading-color);
  display: inline-block;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  text-align: center;
  line-height: 31px;
  font-size: 14px;
  color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-right: 4px;
}

.social-media-light a:last-child {
  margin-right: 0;
}

.social-media-light a:hover {
  background: var(--main-color);
}

.social-media-light a:hover i {
  color: #fff;
}

/********* slider-control *********/
.slider-control-round .owl-nav button {
  height: 46px;
  width: 46px;
  line-height: 48px;
  margin: 0 10px;
  border-radius: 50%;
  border: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border: 0;
  padding: 0;
  cursor: pointer;
  font-size: 18px;
  background: var(--main-color);
}

.slider-control-round .owl-nav button:hover {
  background: var(--heading-color);
}

.slider-control-round .owl-nav .owl-prev {
  margin-left: 0;
}

.slider-control-round .owl-nav .owl-next {
  margin-left: 0;
}

.slider-control-square .owl-nav button {
  height: 60px;
  width: 60px;
  line-height: 62px;
  margin: 0 8px;
  border-radius: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  background: var(--main-color);
  font-size: 20px;
  padding: 0;
  cursor: pointer;
  border: 0;
}

.slider-control-square .owl-nav button:hover {
  background: var(--heading-color);
}

.slider-control-square .owl-nav .owl-prev {
  margin-left: 0;
}

.slider-control-square .owl-nav .owl-next {
  margin-left: 0;
}

.slider-control-right-top .owl-nav {
  position: absolute;
  right: 0;
  top: -95px;
}

.slider-control-dots .owl-dots .owl-dot {
  margin: 0 0;
  border: 0;
  background: none;
}

.slider-control-dots .owl-dots .owl-dot span {
  display: block;
  border-radius: 25px;
  background-color: #F59FAA;
  width: 15px;
  height: 6px;
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.slider-control-dots .owl-dots .owl-dot.active span {
  background: var(--main-color2);
  width: 30px;
}

.slider-control-dots .owl-dots .owl-dot.active span:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.slider-control-dots .owl-dots .owl-dot:focus {
  outline: 0;
}

.single-list-inner {
  margin: 0;
  padding: 0;
}

.single-list-inner li {
  margin-bottom: 6px;
}

.single-list-inner li:last-child {
  margin-bottom: 0;
}

.single-list-inner.style-check li {
  list-style: none;
  padding-left: 0;
}

.single-list-inner.style-check li:after {
  display: none;
}

.single-list-inner.style-check li i,
.single-list-inner.style-check li svg {
  margin-right: 10px;
  color: var(--main-color);
}

.single-list-inner.style-check li.close i,
.single-list-inner.style-check li.close svg {
  color: #F44336;
}

/*--------------------------------------------------------------
# Globals
--------------------------------------------------------------*/
.p-35 {
  padding: 35px;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-5 img {
  border-radius: 5px;
}

.border-radius-6 {
  border-radius: 6px;
}

.border-radius-6 img {
  border-radius: 6px;
}

.ratting-inner {
  color: #FFC107;
}

.bg-base {
  background: var(--main-color);
}

.bg-green {
  background: #1DC295;
}

.bg-blue {
  background: #091D3E;
}

.bg-red {
  background: #F14D5D;
}

.bg-gray {
  background: #F5F9FF;
}

.bg-sky {
  background: #F9F9F9;
}

.bg-black {
  background: var(--heading-color);
}

.bg-light {
  background: #F7FAFC;
}

.bg-light-pink {
  background: #FFF9F5;
}

.bg-relative {
  position: relative;
}

.bg-cover {
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-cover-70 {
  background-size: 70% !important;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-overlay {
  position: relative;
  background-size: cover !important;
}

.bg-overlay:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(10, 24, 81, 0.88);
}

.bg-overlay .container {
  position: relative;
  z-index: 2;
}

.bg-overlay .bg-z-index {
  position: relative;
  z-index: 2;
}

.bg-overlay-base {
  position: relative;
  background-size: cover !important;
}

.bg-overlay-base:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 96, 255, 0.95);
}

.bg-overlay-base .container {
  position: relative;
  z-index: 2;
}

.bg-overlay-base .bg-z-index {
  position: relative;
  z-index: 2;
}

.bg-overlay-light {
  position: relative;
  background-size: cover !important;
}

.bg-overlay-light:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(10, 24, 81, 0.5);
}

.bg-overlay-light .container {
  position: relative;
  z-index: 2;
}

.bg-overlay-light .bg-z-index {
  position: relative;
  z-index: 2;
}

.bg-overlay-black {
  position: relative;
  background-size: cover !important;
}

.bg-overlay-black:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
}

.bg-overlay-black .container {
  position: relative;
  z-index: 2;
}

.bg-overlay-black .bg-z-index {
  position: relative;
  z-index: 2;
}

.bg-parallex {
  background-attachment: fixed;
  background-position: center 0;
}

.image-hover-animate {
  overflow: hidden;
}

.image-hover-animate img {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.image-hover-animate:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.box-shadow {
  -webkit-box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
}

.half-bg-right {
  position: absolute;
  right: 0;
  width: 50%;
  top: 0;
  height: 100%;
}

.half-bg-top {
  position: relative;
}

.half-bg-top:after {
  content: "";
  position: absolute;
  height: 65%;
  width: 100%;
  left: 0;
  top: 0;
  background: var(--heading-color);
  z-index: -1;
}

.bg-overlay-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(#fff), to(rgba(0, 96, 255, 0.3)));
  background-image: linear-gradient(to right, #fff, rgba(0, 96, 255, 0.3));
}

.pd-top-60 {
  padding-top: 60px;
}

.pd-top-90 {
  padding-top: 90px;
}

.pd-top-100 {
  padding-top: 100px;
}

.pd-top-110 {
  padding-top: 110px;
}

.pd-top-115 {
  padding-top: 115px;
}

.pd-top-120 {
  padding-top: 120px;
}

.pd-top-200 {
  padding-top: 200px;
}

.mg-top-90 {
  margin-top: 90px;
}

.mg-top-100 {
  margin-top: 100px;
}

.mg-top-120 {
  margin-top: 120px;
}

.mg-bottom-120 {
  margin-bottom: 120px;
}

.pd-bottom-60 {
  padding-bottom: 60px;
}

.pd-bottom-70 {
  padding-bottom: 70px;
}

.pd-bottom-80 {
  padding-bottom: 80px;
}

.pd-bottom-90 {
  padding-bottom: 90px;
}

.pd-bottom-100 {
  padding-bottom: 100px;
}

.pd-bottom-110 {
  padding-bottom: 110px;
}

.pd-bottom-115 {
  padding-bottom: 115px;
}

.pd-bottom-120 {
  padding-bottom: 120px;
}

.mg-top--100 {
  margin-top: -100px;
}

/************ animate style ************/
.top_image_bounce {
  -webkit-animation: top-image-bounce 3s infinite ease-in-out;
  animation: top-image-bounce 3s infinite ease-in-out;
}

.left_image_bounce {
  -webkit-animation: left-image-bounce 3s infinite ease-in-out;
  animation: left-image-bounce 3s infinite ease-in-out;
}

.right_image_bounce {
  -webkit-animation: right-image-bounce 3s infinite ease-in-out;
  animation: right-image-bounce 3s infinite ease-in-out;
}

.spin_image {
  -webkit-animation: spin 3s infinite ease-in-out;
  animation: spin 3s infinite ease-in-out;
}

@-webkit-keyframes top-image-bounce {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  50% {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }

  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@keyframes top-image-bounce {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  50% {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }

  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
}

@-webkit-keyframes left-image-bounce {
  0% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  50% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}

@keyframes left-image-bounce {
  0% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  50% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
  }
}

@-webkit-keyframes ripple-white3 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1);
  }

  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 100px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 100px rgba(255, 255, 255, 0);
  }
}

@keyframes ripple-white3 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1);
  }

  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 100px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 100px rgba(255, 255, 255, 0);
  }
}

/*------------------------------------------------
    ## Section title
------------------------------------------------*/
.section-title {
  margin-bottom: 40px;
  position: relative;
}

.section-title .sub-title {
  position: relative;
  display: inline-block;
  margin-bottom: 4px;
  color: var(--main-color);
  font-weight: 700;
}

.section-title .sub-title.left-line {
  padding-left: 60px;
}

.section-title .sub-title.left-line:before {
  content: "";
  position: absolute;
  left: 0;
  top: 12px;
  height: 2px;
  width: 40px;
  background: var(--main-color);
}

.section-title .sub-title.right-line:after {
  content: "";
  position: absolute;
  right: -50px;
  top: 12px;
  height: 2px;
  width: 40px;
  background: var(--main-color);
}

.section-title .sub-title.double-line:before {
  content: "";
  position: absolute;
  left: -50px;
  top: 12px;
  height: 2px;
  width: 40px;
  background: var(--main-color);
}

.section-title .sub-title.double-line:after {
  content: "";
  position: absolute;
  right: -50px;
  top: 12px;
  height: 2px;
  width: 40px;
  background: var(--main-color);
}

.section-title .sub-title.left-border {
  padding-left: 12px;
}

.section-title .sub-title.left-border:before {
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  height: 20px;
  width: 4px;
  background: var(--main-color);
}

.section-title .sub-title.right-border:after {
  content: "";
  position: absolute;
  right: -12px;
  top: 1px;
  height: 20px;
  width: 4px;
  background: var(--main-color);
}

.section-title .sub-title.double-border {
  padding-left: 12px;
}

.section-title .sub-title.double-border:before {
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  height: 20px;
  width: 4px;
  background: var(--main-color);
}

.section-title .sub-title.double-border:after {
  content: "";
  position: absolute;
  right: -12px;
  top: 1px;
  height: 20px;
  width: 4px;
  background: var(--main-color);
}

.section-title .title {
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 700;
}

.section-title .content {
  margin-top: 10px;
  margin-bottom: 0;
}

.section-title .content-strong {
  font-weight: 500;
  color: var(--heading-color);
}

.section-title.style-white .sub-title {
  color: var(--main-color);
}

.section-title.style-white .title {
  color: #fff;
}

.section-title.style-white .content {
  color: #fff;
}

.section-title.style-white .small-title {
  color: #fff;
}

/*----------------------------------------------
    # Nav bar
----------------------------------------------*/
.navbar-top {
  background: #091D3E;
  padding: 15px 0;
}

.navbar-top ul {
  margin: 0;
  padding: 0;
  line-height: initial;
}

.navbar-top ul li {
  display: inline-block;
  list-style: none;
  margin-right: 25px;
}

.navbar-top ul li:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: 0;
}

.navbar-top ul li p,
.navbar-top ul li a {
  margin: 0;
  font-size: 14px;
  color: #fff;
}

.navbar-top ul li p img,
.navbar-top ul li a img {
  margin-right: 5px;
}

.navbar-top .container .row .logo1 {
  width: 50px;
}

.navbar-top .container .row .box-logo {
  display: flex;
  justify-content: center;
  justify-items: center;
  gap: 5px;
}

.navbar-top .container .row .box-logo p {
  font-weight: bold;
}

.navbar-top ul li p i,
.navbar-top ul li a i {
  margin-right: 5px;
}

.navbar-top ul li p svg,
.navbar-top ul li a svg {
  margin-right: 5px;
}

.navbar-top ul li a:hover {
  color: var(--main-color);
}

.navbar-top .topbar-right a {
  margin: 0 3px;
}

.navbar-top .topbar-right li a i {
  margin-right: 0;
}

.navbar-top .topbar-right li a svg {
  margin-right: 0;
}

.navbar-top .topbar-right li a:last-child {
  margin-right: 0;
}

.navbar-top .topbar-right li:last-child {
  border-right: 0;
}

.navbar-top.style-2 {
  background: #fff;
}

.navbar-top.style-3 {
  padding: 0;
}

.navbar-top.style-3 .btn {
  border-radius: 0;
}

.navbar-top .media .media-left i {
  font-size: 30px;
  color: var(--main-color);
  margin-right: 10px;
}

.navbar-top .media .media-left svg {
  font-size: 30px;
  color: var(--main-color);
  margin-right: 10px;
}

.navbar-top .media .media-body h6 {
  margin-bottom: 0;
  font-weight: 700;
}

.navbar-top .media .media-body p {
  margin-bottom: 0;
  font-size: 14px;
}

.navbar-area {
  position: absolute;
  width: 100%;
  z-index: 99;
  padding: 0;
}

.navbar-area .nav-container {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  position: relative;
}

.navbar-area .nav-container.navbar-bg {
  position: relative;
  padding-left: 30px;
}

.navbar-area .nav-container.navbar-bg:after {
  content: "";
  position: absolute;
  left: 15px;
  top: 0;
  height: 100%;
  width: 95%;
  background: #fff;
  z-index: -1;
}

.navbar-area .nav-container .logo {
  line-height: 0.9;
}

.navbar-area .nav-container .logo a {
  font-weight: 700;
  font-size: 24px;
  color: #fff;
}

.navbar-area .nav-container .logo a img {
  height: auto;
  max-width: 96px;
}

.navbar-area .nav-container .btn-transparent {
  font-size: 13px;
  font-weight: 700;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav {
  display: block;
  width: 100%;
  text-align: left;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li {
  display: inline-block;
  font-weight: 500;
  line-height: 60px;
  text-transform: capitalize;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li a {
  color: var(--heading-color);
  font-weight: 600;
  font-size: 18px;
  font-family: var(--heading-font);
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li:hover a {
  color: var(--main-color);
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li+li {
  margin-left: 17px;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children {
  position: relative;
  z-index: 0;
  padding-right: 14px;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
  content: "";
  position: absolute;
  right: 3px;
  top: 50%;
  height: 10px;
  width: 2px;
  background: var(--heading-color);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  margin-top: 0;
  z-index: -1;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:after {
  content: "";
  position: absolute;
  right: -1px;
  top: 30px;
  height: 2px;
  width: 10px;
  background: var(--heading-color);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  z-index: -1;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover:before {
  opacity: 0;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover:after {
  background: var(--main-color);
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:hover>.sub-menu {
  visibility: visible;
  opacity: 1;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
  position: absolute;
  text-align: left;
  min-width: 210px;
  margin: 0;
  padding: 0;
  list-style: none;
  left: 0;
  top: 100%;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  z-index: 9;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 0;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu.border-bt0 {
  border-bottom: 0px !important;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
  display: block;
  margin-left: 0;
  line-height: 22px;
  font-size: 15px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-bottom: 1px solid #f5f5f5;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
  display: block;
  padding: 10px 15px;
  white-space: nowrap;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #050a30;
  font-size: 15px;
  font-weight: 500;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: relative;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:hover {
  background: #061539;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:hover a {
  color: #fff;
  padding: 10px 20px 10px 20px;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:hover a:before {
  visibility: visible;
  opacity: 1;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:last-child {
  border-bottom: 0;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children {
  position: relative;
  z-index: 0;
  padding-right: 0px;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:before {
  position: absolute;
  right: 15px;
  top: 50%;
  content: "\f105";
  font-family: "fontawesome";
  /* IE 9 */
  -webkit-transform: translateY(-50%);
  /* Chrome, Safari, Opera */
  transform: translateY(-50%);
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children>.sub-menu {
  left: 100%;
  top: 20px;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children>.sub-menu .sub-menu .sub-menu {
  left: auto;
  right: 100%;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:hover>.sub-menu {
  visibility: visible;
  opacity: 1;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .menu-item-has-children:hover>.sub-menu li:hover:before {
  color: #fff;
}

.navbar-area .nav-container .navbar-collapse .navbar-nav>li {
  margin-right: 12px;
}

.navbar-area-2 {
  background: var(--main-color);
}

.navbar-area-2 .nav-container .navbar-collapse .navbar-nav>li {
  padding: 0 32px 0 15px !important;
  margin: 0 !important;
}

.navbar-area-2 .nav-container .navbar-collapse .navbar-nav>li.menu-item-has-children:before,
.navbar-area-2 .nav-container .navbar-collapse .navbar-nav>li.menu-item-has-children:after {
  background: #fff;
  right: 15px;
}

.navbar-area-2 .nav-container .navbar-collapse .navbar-nav>li.menu-item-has-children:before {
  right: 19px;
}

.navbar-area-2 .nav-container .navbar-collapse .navbar-nav>li a {
  color: #fff;
}

.navbar-area-2 .nav-container .navbar-collapse .navbar-nav>li:hover {
  background: var(--heading-color);
  color: #fff;
}

.navbar-area-2 .nav-container .navbar-collapse .navbar-nav>li:hover a {
  color: #fff;
}

.navbar-area-2 .nav-container .navbar-collapse .navbar-nav>li:hover:after {
  background: #fff !important;
}

.navbar-area-2 .nav-right-part .search-bar-btn {
  color: #fff;
}

.navbar-area-2 .nav-right-part .btn {
  height: 60px;
  line-height: 60px;
}

.navbar-area-3 .nav-container {
  padding: 10px 15px;
  border-bottom: 1px solid #738193;
}

.navbar-area-3 .nav-container .navbar-collapse .navbar-nav>li.menu-item-has-children:before,
.navbar-area-3 .nav-container .navbar-collapse .navbar-nav>li.menu-item-has-children:after {
  background: #fff;
}

.navbar-area-3 .nav-container .navbar-collapse .navbar-nav>li a {
  color: #fff;
}

.navbar-area-3 .nav-right-part .search-bar-btn {
  color: #fff;
}

.navbar-area-3 .nav-right-part .social-area a {
  color: #fff;
}

.navbar-nav {
  opacity: 0;
  margin-right: -30px;
  visibility: hidden;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.sticky-active {
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 10px 20px 0 rgba(46, 56, 220, 0.05);
  box-shadow: 0 10px 20px 0 rgba(46, 56, 220, 0.05);
  border-bottom: 0;
  background: #fff;
}

.sticky-active.navbar-area-2 {
  background: var(--main-color);
}

.sticky-active.navbar-area-3 {
  background: var(--heading-color);
}

.sticky-active.navbar-area-3 .nav-container {
  border-bottom: 0;
}

.menu-open {
  opacity: 1;
  margin-right: 0;
  visibility: visible;
}

.bar1 {
  width: 32px;
  height: 2px;
  margin-bottom: 5px;
  position: absolute;
  background: #fff;
  z-index: 9999;
  top: 10px;
  right: -5px;
}

.bar2 {
  width: 24px;
  height: 2px;
  margin-bottom: 5px;
  position: absolute;
  background: #fff;
  z-index: 9999;
  top: 17px;
  right: -5px;
}

.bar3 {
  width: 18px;
  height: 2px;
  margin-bottom: 5px;
  position: absolute;
  background: #fff;
  z-index: 9999;
  top: 24px;
  right: -5px;
}

.responsive-mobile-menu button:focus {
  outline: none;
  border: none;
}

/**************** nav-left-part *************/
.header-cat-menu {
  margin: 0;
  padding: 0;
  margin-left: 58px;
}

.header-cat-menu li {
  position: relative;
  margin-right: 0;
  cursor: pointer;
  list-style: none;
}

.header-cat-menu li img {
  margin-top: -3px;
  margin-right: 5px;
}

.header-cat-menu li a {
  color: #022334;
  font-weight: 500;
  font-size: 18px;
}

.header-cat-menu li .sub-menu {
  position: absolute;
  left: 0;
  top: 40px;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 9;
  border: none;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  min-width: 210px;
}

.header-cat-menu li .sub-menu li {
  display: block;
  margin-left: 0;
  line-height: 22px;
  font-size: 15px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-bottom: 1px solid #f5f5f5;
}

.header-cat-menu li .sub-menu li a {
  display: block;
  padding: 10px 20px;
  white-space: nowrap;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #050a30;
  font-size: 14px;
  font-weight: 500;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: relative;
}

.header-cat-menu li .sub-menu li a:before {
  position: absolute;
  left: 17px;
  top: 50%;
  content: "\f105";
  font-family: "fontawesome";
  /* IE 9 */
  -webkit-transform: translateY(-50%);
  /* Chrome, Safari, Opera */
  transform: translateY(-50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: #fff;
}

.header-cat-menu li .sub-menu li:hover {
  background: #061539;
}

.header-cat-menu li .sub-menu li:hover a {
  color: #fff;
  padding: 10px 20px 10px 30px;
}

.header-cat-menu li .sub-menu li:hover a:before {
  visibility: visible;
  opacity: 1;
}

.header-cat-menu li .sub-menu li:last-child {
  border-bottom: 0;
}

.header-cat-menu li:hover>a {
  color: var(--main-color);
}

.header-cat-menu li:hover ul {
  opacity: 1;
  visibility: visible;
  -webkit-animation: menuslide 300ms;
  animation: menuslide 300ms;
}

/**************** nav-right-part *************/
.nav-right-part .btn {
  height: 80px;
  line-height: 80px;
  color: #fff;
  border-radius: 0;
}

.nav-right-part .btn i {
  font-size: 14px;
  padding-top: 14px;
  padding-left: 6px;
}

.nav-right-part a {
  margin-left: 15px;
  color: var(--heading-color);
}

.nav-right-part .search-bar:hover {
  background: var(--main-color);
  border: 1px solid var(--main-color);
}

.nav-right-part-desktop {
  margin-left: 20px;
}

.nav-right-part-mobile {
  display: none;
}

/**************** sidebar-menu *************/
.sidebar-menu {
  width: 0;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: #fff;
  z-index: 999;
  overflow-y: auto;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.sidebar-menu .sidebar-inner {
  position: relative;
  top: 150px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding: 90px 40px 50px 40px;
}

.sidebar-menu .sidebar-inner .thumb {
  margin-bottom: 15px;
}

.sidebar-menu .sidebar-inner p {
  margin-bottom: 28px;
}

.sidebar-menu .sidebar-inner .sidebar-address {
  margin-bottom: 35px;
}

.sidebar-menu .sidebar-inner .sidebar-address ul {
  margin: 0;
  padding: 0;
}

.sidebar-menu .sidebar-inner .sidebar-address ul li {
  list-style: none;
  margin-bottom: 4px;
}

.sidebar-menu .sidebar-inner .sidebar-address ul li:last-child {
  margin-bottom: 0;
}

.sidebar-menu .sidebar-inner .sidebar-address ul li i {
  margin-right: 6px;
  width: 25px;
}

.sidebar-menu .sidebar-inner .sidebar-subscribe {
  position: relative;
  margin-bottom: 40px;
}

.sidebar-menu .sidebar-inner .sidebar-subscribe input {
  width: 100%;
  border: 1px solid #d6dadf;
  height: 42px;
  padding: 0 45px 0 12px;
}

.sidebar-menu .sidebar-inner .sidebar-subscribe button {
  position: absolute;
  right: 0;
  top: 0;
  height: 42px;
  width: 42px;
  background: #97b3d0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 0;
  color: var(--heading-color);
  cursor: pointer;
}

.sidebar-menu .sidebar-inner .sidebar-subscribe button:hover {
  background: var(--main-color);
  color: #fff;
}

.sidebar-menu .sidebar-inner .social-media li a {
  background: #ced4da;
}

.sidebar-menu .sidebar-inner .social-media li a:hover {
  background: var(--main-color);
  color: #fff;
}

.sidebar-menu .sidebar-menu-close {
  background-color: var(--main-color);
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  z-index: 999;
}

.sidebar-menu.active {
  width: 360px;
  opacity: 1;
  visibility: visible;
}

.sidebar-menu.active .sidebar-inner {
  top: 0;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.7s ease-out 0.8s;
  transition: all 0.7s ease-out 0.8s;
}

@media only screen and (max-width: 991px) {
  .navbar-area {
    background: #fff;
  }

  .navbar-area .nav-container {
    padding: 5px 0;
  }

  .nav-right-part {
    margin-right: 60px;
  }

  .nav-right-part .btn .right {
    padding-left: 5px;
    font-size: 13px;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav>li {
    margin-right: 0;
    padding-right: 0;
  }

  .navbar-area .nav-container {
    position: relative;
    z-index: 0;
  }

  .navbar-area .nav-container .navbar-toggler {
    padding: 0px;
  }

  .navbar-area .nav-container .navbar-collapse {
    margin-top: 13px;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav {
    display: block;
    margin-top: 20px;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    display: block;
    text-align: left;
    line-height: 30px;
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
    display: block;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li:last-child {
    border-bottom: none;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li+li {
    margin-left: 0;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
    top: 26px;
    right: 3px !important;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
    position: initial;
    display: block;
    width: 100%;
    border-top: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: 0;
    padding-bottom: 0;
    height: auto;
    overflow: hidden;
    max-height: 250px;
    overflow-y: scroll;
    background-color: transparent;
    border-radius: 10px;
    padding: 0px;
    border-bottom: none;
    display: none;
    -webkit-transition: none;
    transition: none;
    visibility: visible;
    opacity: 1;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu .sub-menu .menu-item-has-children:before {
    content: "\f107";
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li {
    padding: 0;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li.menu-item-has-children:hover:before {
    top: 30px;
    color: #fff;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li+li {
    border-top: none;
  }
}

.sopen {
  display: block !important;
}

.toggle-btn {
  left: auto;
  right: 0;
  position: absolute;
  top: 8px;
  width: 40px;
  height: 40px;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  border: 0;
  background: transparent;
}

.toggle-btn .icon-left {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  height: 2px;
  width: 11px;
  top: 18px;
  background-color: var(--heading-color);
  left: 7px;
}

.toggle-btn .icon-left:before {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: var(--heading-color);
  content: "";
  top: -7px;
  left: 0;
}

.toggle-btn .icon-left:after {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: var(--heading-color);
  content: "";
  top: 7px;
  left: 0;
}

.toggle-btn .icon-left:hover {
  cursor: pointer;
}

.toggle-btn .icon-right {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  height: 2px;
  width: 11px;
  top: 18px;
  background-color: var(--heading-color);
  left: 18px;
}

.toggle-btn .icon-right:before {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: var(--heading-color);
  content: "";
  top: -7px;
  left: 0;
}

.toggle-btn .icon-right:after {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  position: absolute;
  width: 11px;
  height: 2px;
  background-color: var(--heading-color);
  content: "";
  top: 7px;
  left: 0;
}

.toggle-btn .icon-right:hover {
  cursor: pointer;
}

.toggle-btn.open .icon-left {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  background: transparent;
}

.toggle-btn.open .icon-left:before {
  -webkit-transform: rotateZ(45deg) scaleX(1.4) translate(2px, 1px);
  transform: rotateZ(45deg) scaleX(1.4) translate(2px, 1px);
}

.toggle-btn.open .icon-left:after {
  -webkit-transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -1px);
  transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -1px);
}

.toggle-btn.open .icon-right {
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  background: transparent;
}

.toggle-btn.open .icon-right:before {
  -webkit-transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 1px);
  transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 1px);
}

.toggle-btn.open .icon-right:after {
  -webkit-transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -1px);
  transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -1px);
}

.toggle-btn:hover {
  cursor: pointer;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    font-size: 16px;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li+li {
    margin-left: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .responsive-mobile-menu {
    display: block;
    width: 100%;
    position: relative;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1199px) {
  .margin-xlt-80 {
    margin-top: 0px;
  }

  .contact-widget .contact_info_list li.single-info-item .details {
    padding-left: 25px;
  }
}

@media only screen and (max-width: 991px) {

  /* .single-project-inner .thumb {
    width: 100%;
    height: 100%;
  } */
  .sticky-active.navbar-area-2 {
    background: white;
    /* box-shadow: #000202; */
  }

  .navbar-area .logo {
    padding-top: 0px !important;
  }

  .widget ul {
    text-align: left;
  }

  .navbar-collapse {
    background: #fff;
    margin-top: 0px;
    width: 100%;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before {
    right: 20px;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav {
    margin-bottom: 20px;
  }

  .navbar-area {
    padding-bottom: 0px;
  }

  .bar1,
  .bar2,
  .bar3 {
    background: #fff;
  }

  .party-box-wrapper {
    padding: 50px 0px;
  }

  .party-box-wrapper .party-box-content h4 {
    font-size: 30px;
    line-height: 45px;
  }

  .footer-area .copyright-area-inner {
    padding: 20px;
  }

  .navbar-expand-lg .navbar-collapse {
    margin-top: 0px;
  }

  .contact-widget .contact_info_list li.single-info-item .details {
    padding-left: 25px;
  }

  .footer-area .footer-top .widget.widget_nav_menu ul li a {
    font-size: 14px;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu {
    padding: 0 0 0 20px;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a {
    padding: 12px 0;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:last-child a {
    padding-bottom: 3px;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li a:hover i {
    opacity: 0;
    margin-right: -18px;
  }

  .nav-right-part-mobile {
    display: block;
  }

  .nav-right-part-desktop {
    display: none;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children .sub-menu li:hover {
    background: none;
  }

  .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
    color: #333333;
  }
}

/* Tablet Layout wide: 767px. */
@media only screen and (max-width: 767px) {
  .logo-wrapper.mobile-logo {
    display: block;
    width: 100%;
  }

  .responsive-mobile-menu {
    display: block;
    width: 100%;
    position: relative;
  }

  .responsive-mobile-menu .navbar-toggler {
    position: absolute;
    left: calc(100% - 130px);
    top: 10px;
  }

  .table-responsive {
    display: block !important;
  }

  .btn-custom-default,
  .btn-custom-white {
    font-size: 14PX;
    line-height: 33px;
    padding: 6px 20px;
  }

  .navbar-area .logo {
    padding-top: 0px !important;
  }
}

/* medium tablet layout 599px */
@media only screen and (max-width: 575px) {
  .navbar-area .nav-container {
    margin: 0px 0px;
  }

  .navbar-area .logo {
    padding-top: 10px;
  }

  .widget.footer-widget .subscribe-form.subscribe-form-style2 .form-control {
    padding: 15px 20px;
  }

  .widget.footer-widget .subscribe-form.subscribe-form-style2 .btn {
    padding: 15px 20px;
  }

  .search-popup .search-form {
    min-width: 350px;
  }
}

@media only screen and (max-width: 375px) {

  .btn-custom-default,
  .btn-custom-white {
    padding: 5px 18px;
  }

  .search-popup .search-form .form-group .form-control,
  .search-popup .search-form .submit-btn {
    height: 45px;
  }

  .search-popup .search-form {
    min-width: 300px;
  }
}

@media only screen and (max-width: 320px) {
  .search-popup .search-form {
    min-width: 265px;
  }

  .responsive-mobile-menu .navbar-toggler {
    left: calc(100% - 95px);
  }
}

/********* all banner style ***********/
.banner-inner {
  position: relative;
  z-index: 5;
}

.banner-inner .sub-title {
  color: var(--main-color);
}

.banner-inner .title {
  font-size: 78px;
  line-height: 1.05;
  font-weight: 700;
}

.banner-inner .title span {
  color: var(--main-color);
}

.banner-inner .content {
  font-size: 18px;
  margin-top: 20px;
}

.banner-inner .btn-wrap {
  margin-top: 40px;
}

.banner-inner.style-white .title {
  color: #fff;
}

.banner-inner.style-white .content {
  color: #fff;
}

.banner-area {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.7),
}

.banner-area .banner-bg-img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.banner-slider .item {
  position: relative;
  overflow: hidden;
}

.banner-slider .item.bg-overlay:after {
  background: rgba(10, 24, 81, 0.9);
}

.banner-slider .item .banner3-shape {
  content: "";
  position: absolute;
  right: -300px;
  top: 0;
  background: rgba(0, 96, 255, 0.3);
  height: 100%;
  width: 100%;
  -webkit-transition: all 1500ms ease;
  transition: all 1500ms ease;
  -webkit-transition-delay: 200ms;
  transition-delay: 200ms;
}

.banner-slider .item .shape-1 {
  -webkit-clip-path: polygon(70% 0, 85% 0%, 20% 100%, 5% 100%);
  clip-path: polygon(70% 0, 85% 0%, 20% 100%, 5% 100%);
}

.banner-slider .item .shape-2 {
  -webkit-clip-path: polygon(100% 39%, 100% 62%, 77% 100%, 61% 100%);
  clip-path: polygon(100% 39%, 100% 62%, 77% 100%, 61% 100%);
}

.banner-slider .item .shape-3 {
  -webkit-clip-path: polygon(18% 0, 31% 0, 0 57%, 0 34%);
  clip-path: polygon(18% 0, 31% 0, 0 57%, 0 34%);
  left: -300px;
}

.banner-slider .owl-item.active .item .banner3-shape {
  height: 100%;
  right: 0;
}

.banner-slider .owl-item.active .item .shape-3 {
  left: 0;
}

.banner-slider .owl-prev {
  position: absolute;
  left: 60px;
  top: 50%;
  margin-top: -20px;
}

.banner-slider .owl-next {
  position: absolute;
  right: 60px;
  top: 50%;
  margin-top: -20px;
}

.banner-area-1 {
  padding: 328px 0 225px 0;
}

.banner-area-2 {
  padding: 250px 0 250px 0;
}

.banner-area-3 .banner-inner {
  padding: 250px 0 250px 0;
}

.banner-slider .owl-item.active .s-animate-1 {
  -webkit-animation: 1.5s 0.3s fadeInUp both;
  animation: 1.5s 0.3s fadeInUp both;
}

.banner-slider .owl-item.active .s-animate-2 {
  -webkit-animation: 1.5s 0.7s fadeInUp both;
  animation: 1.5s 0.7s fadeInUp both;
}

.banner-slider .owl-item.active .s-animate-3 {
  -webkit-animation: 1.5s 1s fadeInUp both;
  animation: 1.5s 1s fadeInUp both;
}

.banner-slider .owl-item.active .s-animate-4 {
  -webkit-animation: 1.5s 1.3s fadeInUp both;
  animation: 1.5s 1.3s fadeInUp both;
}

.banner-slider .owl-item.active .s-animate-5 {
  -webkit-animation: 1.5s 1.4s fadeInUp both;
  animation: 1.5s 1.4s fadeInUp both;
}

/*----------------------------------------
  ## Search Popup
----------------------------------------*/
.td-search-popup.active .search-form {
  visibility: visible;
  opacity: 1;
  width: 40%;
  z-index: 9999;
}

.td-search-popup .search-form {
  width: 35%;
  position: absolute;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 999;
}

.td-search-popup .search-form .form-group .form-control {
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 54px;
  padding: 0 75px 0 25px;
  background: #f7f9fb;
}

.td-search-popup .search-form .form-group .form-control:hover,
.td-search-popup .search-form .form-group .form-control:focus,
.td-search-popup .search-form .form-group .form-control:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.td-search-popup .search-form .submit-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 54px;
  border: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #ffffff;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  padding: 0;
  text-align: center;
  cursor: pointer;
  background: var(--main-color);
}

.td-search-popup .search-form .submit-btn:hover,
.td-search-popup .search-form .submit-btn:focus,
.td-search-popup .search-form .submit-btn:active {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.body-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.95);
  z-index: 999;
  content: "";
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVBAMAAABbObilAAAAMFBMVEVMaXH////////////////////////////////////////////////////////////6w4mEAAAAD3RSTlMAlAX+BKLcA5+b6hJ7foD4ZP1OAAAAkUlEQVR4XkWPoQ3CUBQAL4SktoKAbCUjgAKLJZ2ABYosngTJCHSD6joUI6BZgqSoB/+Shqde7sS9x3OGk81fdO+texMtRVTia+TsQtHEUJLdohJfgNNPJHyEJPZTsWLoxShqsWITazEwqePAn69Sw2TUxk1+euPis3EwaXy8RMHSZBIlRcKKnC5hRctjMf57/wJbBlAIs9k1BAAAAABJRU5ErkJggg==), progress;
}

.body-overlay.active {
  visibility: visible;
  opacity: 0.97;
}

.mfp-zoom-in {
  /* animate in */
  /* animate out */
}

.mfp-zoom-in .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.mfp-zoom-in.mfp-ready .mfp-content {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.7;
}

.mfp-zoom-in.mfp-removing .mfp-content {
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/************ single-inpur-inner ************/
.single-input-inner {
  margin-bottom: 20px;
}

.single-input-inner input {
  width: 100%;
  height: 48px;
  border: 0 !important;
  padding: 0 18px;
  font-weight: 300;
}

.single-input-inner input::-webkit-input-placeholder {
  color: #adadad;
}

.single-input-inner input::-moz-placeholder {
  color: #adadad;
}

.single-input-inner input:-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner input::-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner input::placeholder {
  color: #adadad;
}

.single-input-inner textarea {
  width: 100%;
  border: 0 !important;
  height: 130px;
  border-radius: 6px;
  padding: 14px 18px;
  font-weight: 300;
}

.single-input-inner textarea::-webkit-input-placeholder {
  color: #adadad;
}

.single-input-inner textarea::-moz-placeholder {
  color: #adadad;
}

.single-input-inner textarea:-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner textarea::-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner textarea::placeholder {
  color: #adadad;
}

.single-input-inner .single-select {
  width: 100%;
  height: 48px;
  line-height: 48px;
  border: 0 !important;
  border-radius: 30px;
  padding: 0 18px;
  margin-bottom: 20px;
}

.single-input-inner .single-select .list {
  width: 100%;
}

.single-input-inner.style-border input {
  border: 1px solid rgba(234, 225, 214, 0.7) !important;
}

.single-input-inner.style-border input::-webkit-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border input::-moz-placeholder {
  color: #adadad;
}

.single-input-inner.style-border input:-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border input::-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border input::placeholder {
  color: #adadad;
}

.single-input-inner.style-border textarea {
  border: 1px solid rgba(234, 225, 214, 0.7) !important;
  background: transparent;
}

.single-input-inner.style-border textarea::-webkit-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border textarea::-moz-placeholder {
  color: #adadad;
}

.single-input-inner.style-border textarea:-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border textarea::-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border textarea::placeholder {
  color: #adadad;
}

.single-input-inner.style-border .single-select {
  border: 1px solid rgba(234, 225, 214, 0.7) !important;
  color: #adadad;
}

.single-input-inner.style-border-bottom input {
  border-bottom: 1px solid rgba(234, 225, 214, 0.7) !important;
  background: transparent;
  padding: 0;
}

.single-input-inner.style-border-bottom input::-webkit-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom input::-moz-placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom input:-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom input::-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom input::placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom textarea {
  border-bottom: 1px solid rgba(234, 225, 214, 0.7) !important;
}

.single-input-inner.style-border-bottom textarea::-webkit-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom textarea::-moz-placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom textarea:-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom textarea::-ms-input-placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom textarea::placeholder {
  color: #adadad;
}

.single-input-inner.style-border-bottom .single-select {
  border-bottom: 1px solid rgba(234, 225, 214, 0.7) !important;
  background: transparent;
  color: #adadad;
}

.single-input-inner.style-bg input {
  background: #F2F7FF;
  border-radius: 5px;
}

.single-input-inner.style-bg input::-webkit-input-placeholder {
  color: #616161;
}

.single-input-inner.style-bg input::-moz-placeholder {
  color: #616161;
}

.single-input-inner.style-bg input:-ms-input-placeholder {
  color: #616161;
}

.single-input-inner.style-bg input::-ms-input-placeholder {
  color: #616161;
}

.single-input-inner.style-bg input::placeholder {
  color: #616161;
}

.single-input-inner.style-bg textarea {
  background: #F2F7FF;
}

.single-input-inner.style-bg textarea::-webkit-input-placeholder {
  color: #616161;
  border-radius: 5px;
}

.single-input-inner.style-bg textarea::-moz-placeholder {
  color: #616161;
  border-radius: 5px;
}

.single-input-inner.style-bg textarea:-ms-input-placeholder {
  color: #616161;
  border-radius: 5px;
}

.single-input-inner.style-bg textarea::-ms-input-placeholder {
  color: #616161;
  border-radius: 5px;
}

.single-input-inner.style-bg textarea::placeholder {
  color: #616161;
  border-radius: 5px;
}

.single-input-inner.style-bg .single-select {
  background: #F2F7FF;
  color: #616161;
  border-radius: 5px;
}

.single-input-inner.style-bg-none input {
  background: transparent;
  color: #fff;
}

.single-input-inner.style-bg-none input::-webkit-input-placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none input::-moz-placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none input:-ms-input-placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none input::-ms-input-placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none input::placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none textarea {
  background: transparent;
  color: #fff;
}

.single-input-inner.style-bg-none textarea::-webkit-input-placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none textarea::-moz-placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none textarea:-ms-input-placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none textarea::-ms-input-placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none textarea::placeholder {
  color: #fff;
}

.single-input-inner.style-bg-none .single-select {
  background: transparent;
  color: #fff;
}

.contact-form-inner {
  padding: 0 30px 30px;
  border-radius: 7px;
  overflow: hidden;
  background-size: cover;
}

.contact-form-inner .single-input-inner.style-border-bottom input {
  border-bottom: 1px solid #fff !important;
}

.contact-form-inner .single-input-inner.style-border-bottom input::-webkit-input-placeholder {
  color: #fff;
}

.contact-form-inner .single-input-inner.style-border-bottom input::-moz-placeholder {
  color: #fff;
}

.contact-form-inner .single-input-inner.style-border-bottom input:-ms-input-placeholder {
  color: #fff;
}

.contact-form-inner .single-input-inner.style-border-bottom input::-ms-input-placeholder {
  color: #fff;
}

.contact-form-inner .single-input-inner.style-border-bottom input::placeholder {
  color: #fff;
}

.blog-comment-form textarea {
  background: #FBFBFB;
  border: 1px solid #FBFBFB;
}

/******** single-intro-inner ********/
.single-intro-inner {
  -webkit-box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.05);
  box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.05);
  padding: 30px;
  background: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 30px;
}

.single-intro-inner .thumb {
  margin-bottom: 10px;
}

.single-intro-inner .thumb i {
  font-size: 40px;
  color: var(--main-color);
  margin-right: 15px;
}

.single-intro-inner .thumb h4 {
  margin-bottom: 0;
}

.single-intro-inner .details p {
  margin-bottom: 0;
}

.single-intro-inner:hover {
  -webkit-box-shadow: 0px 10px 30px rgba(0, 33, 71, 0.12);
  box-shadow: 0px 10px 30px rgba(0, 33, 71, 0.12);
}

/********* content style ***********/
.single-exp-inner {
  margin-bottom: 30px;
}

.single-exp-inner h2 {
  color: var(--main-color);
  margin-bottom: 0;
}

.single-exp-inner h2 sub {
  font-size: 20px;
  bottom: 0;
  color: #616161;
  margin-left: -5px;
}

.single-exp-inner.style-white h2 {
  color: #fff;
}

.single-exp-inner.style-white h2 sub {
  color: #fff;
}

.single-exp-inner.style-white h5 {
  color: #fff;
}

.single-choose-inner {
  margin-bottom: 30px;
}

.single-choose-inner .icon {
  height: 55px;
  width: 55px;
  line-height: 60px;
  text-align: center;
  background: var(--main-color);
  border-radius: 50% 0 50% 50%;
  color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-right: 20px;
  margin-top: 5px;
  font-size: 20px;
}

.single-choose-inner:hover .icon {
  background: var(--heading-color);
}

.single-choose-inner .media-body p {
  margin-bottom: 0;
}

.single-service-inner {
  padding: 40px 30px 35px 30px;
  border-radius: 5px;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
}

.single-service-inner .icon-box {
  margin-bottom: 13px;
}

.single-service-inner .icon-box i {
  font-size: 44px;
}

.single-service-inner .icon-box-bg {
  background: var(--main-color);
  height: 90px;
  width: 90px;
  line-height: 112px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 15px;
}

.single-service-inner .icon-box-bg i {
  font-size: 40px;
  color: #fff;
}

.single-service-inner .details h3 {
  font-weight: 600;
}

.single-service-inner .details p {
  margin-bottom: 0;
}

.single-service-inner .details-hover-wrap {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--heading-color);
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-service-inner .details-hover-wrap h3 {
  color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-service-inner .details-hover-wrap p {
  color: #fff;
}

.single-service-inner .details-hover-wrap a {
  margin-top: 18px;
}

.single-service-inner .details-hover-wrap .details-hover {
  margin-top: 40px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-service-inner.style-white {
  background: rgba(3, 11, 24, 0.6);
}

.single-service-inner.style-white .icon-box i {
  color: var(--main-color);
}

.single-service-inner.style-white h3 {
  color: #fff;
}

.single-service-inner.style-white p {
  color: #fff;
}

.single-service-inner.style-white:hover {
  background: var(--main-color);
}

.single-service-inner.style-white:hover .icon-box i {
  color: #fff;
}

.single-service-inner.style-white .details h3:hover {
  text-decoration: underline;
}

.single-service-inner.style-white .details h3:hover a {
  color: #fff;
}

.single-service-inner:hover .details-hover-wrap {
  visibility: visible;
  opacity: 1;
}

.single-service-inner:hover .details-hover-wrap .details-hover {
  margin-top: 0;
}

.single-service-inner.style-2 {
  padding: 40px 30px 85px 30px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-size: 108% 109%;
  background-repeat: no-repeat;
  background-position: -13px -13px;
}

.single-service-inner.style-2 .icon-box i,
.single-service-inner.style-2 .icon-box svg {
  color: var(--main-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-service-inner.style-2 .details .read-more-btn {
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  background: var(--main-color);
  display: inline-block;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: 15px;
  margin-left: -23px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-service-inner.style-2:hover .details .read-more-btn {
  background: var(--heading-color);
}

.single-service-inner.style-hover-base .icon-box i {
  color: var(--main-color);
}

.single-service-inner.style-hover-base .details h3:hover a {
  color: var(--heading-color);
}

.single-service-inner.style-hover-base:hover {
  background: var(--main-color);
}

.single-service-inner.style-hover-base:hover .icon-box i {
  color: #fff;
}

.single-service-inner.style-hover-base:hover h3 {
  color: #fff;
}

.single-service-inner.style-hover-base:hover p {
  color: #fff;
}

.single-list-inner {
  margin: 0;
  padding: 0;
}

.single-list-inner li {
  list-style: none;
  position: relative;
  padding-left: 18px;
}

.single-list-inner li:after {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  top: 9px;
  left: 0;
  border-radius: 50%;
  background: var(--main-color);
}

.single-team-inner {
  background: #F4F8FF;
  position: relative;
  margin-bottom: 30px;
}

.single-team-inner .thumb {
  overflow: hidden;
}

.single-team-inner .thumb img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 100%;
}

.single-team-inner .details-inner {
  padding: 20px;
  position: relative;
  z-index: 4;
}

.single-team-inner .details-inner h4 {
  margin-bottom: 0;
  font-weight: 600;
}

.single-team-inner .details-inner p {
  color: var(--main-color);
  margin-bottom: 0;
}

.single-team-inner .hover-details-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--main-color);
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-team-inner .hover-details-wrap h4 {
  margin-bottom: 0;
  color: #fff;
}

.single-team-inner .hover-details-wrap p {
  margin-bottom: 0;
  color: #fff;
}

.single-team-inner .hover-details-wrap .hover-details-inner {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-top: 40px;
}

.single-team-inner:hover .thumb img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.single-team-inner:hover .hover-details-wrap {
  visibility: visible;
  opacity: 1;
}

.single-team-inner:hover .hover-details-wrap .hover-details-inner {
  margin-top: 0;
}

.single-team-inner:hover .hover-details-wrap h4:hover a {
  color: #fff;
  text-decoration: underline;
}

.single-team-inner.style-1 {
  border-radius: 5px;
  overflow: hidden;
}

.single-team-inner.style-1 .thumb {
  position: relative;
}

.single-team-inner.style-1 .thumb:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 96, 255, 0.6);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-team-inner.style-1 .thumb .social-media {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: 100%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s;
}

.single-team-inner.style-1 .details-wrap {
  position: relative;
}

a {
  text-decoration: none;
}

.single-team-inner.style-1 .details-wrap:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 165%;
  width: 101%;
  -webkit-clip-path: polygon(57% 28%, 100% 39%, 100% 100%, 0 100%, 0 39%);
  clip-path: polygon(57% 28%, 100% 39%, 100% 100%, 0 100%, 0 39%);
  background: #F4F8FF;
  z-index: 2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-team-inner.style-1 .details-wrap:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 165%;
  width: 100%;
  -webkit-clip-path: polygon(57% 22%, 100% 40%, 100% 100%, 0 100%, 0 31%);
  clip-path: polygon(57% 22%, 100% 40%, 100% 100%, 0 100%, 0 31%);
  background: var(--main-color);
  z-index: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-team-inner.style-1:hover .thumb:after {
  visibility: visible;
  opacity: 1;
}

.single-team-inner.style-1:hover .thumb .social-media {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, -51%);
  transform: translate(-50%, -51%);
}

.single-team-inner.style-1:hover .details-wrap:before {
  -webkit-clip-path: polygon(47% 28%, 100% 39%, 100% 100%, 0 100%, 0 39%);
  clip-path: polygon(47% 28%, 100% 39%, 100% 100%, 0 100%, 0 39%);
}

.single-team-inner.style-1:hover .details-wrap:after {
  -webkit-clip-path: polygon(47% 22%, 100% 40%, 100% 100%, 0 100%, 0 31%);
  clip-path: polygon(47% 22%, 100% 40%, 100% 100%, 0 100%, 0 31%);
}

.single-team-inner.style-2 {
  background: transparent;
  padding-bottom: 15px;
}

.single-team-inner.style-2:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  -webkit-clip-path: polygon(100% 0, 100% 86%, 50% 100%, 0 85%, 0 0);
  clip-path: polygon(100% 0, 100% 86%, 50% 100%, 0 85%, 0 0);
  background: #06142B;
  height: 100%;
  width: 100%;
  z-index: 1;
  border-radius: 5px;
}

.single-team-inner.style-2:after {
  content: "";
  position: absolute;
  left: 3%;
  top: -5px;
  -webkit-clip-path: polygon(100% 0, 100% 86%, 50% 100%, 0 85%, 0 0);
  clip-path: polygon(100% 0, 100% 86%, 50% 100%, 0 85%, 0 0);
  background: var(--main-color);
  height: 107%;
  width: 94%;
  z-index: 0;
  border-radius: 5px;
}

.single-team-inner.style-2 .thumb {
  overflow: inherit;
  margin-top: 50px;
  position: relative;
  z-index: 2;
}

.single-team-inner.style-2 .thumb img {
  height: 110px;
  width: 110px;
  border-radius: 50%;
  margin-top: -50px;
  border: 12px solid #05214E;
}

.single-team-inner.style-2 .details-inner h4 {
  color: #fff;
}

.single-team-inner.style-2 .details-inner p {
  color: #fff;
}

.single-team-inner.style-2 .details-inner .social-media li a {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}

.single-team-inner.style-2 .details-inner .social-media li a:hover {
  background: var(--main-color);
  border: 1px solid var(--main-color);
}

.single-team-inner.style-2:hover .thumb img {
  border: 12px solid var(--main-color);
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.single-work-inner {
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
}

.single-work-inner .count-inner {
  position: relative;
  margin-bottom: 18px;
  display: inline-block;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-work-inner .count-inner h2 {
  font-weight: 700;
  background: var(--main-color);
  display: inline-block;
  height: 78px;
  width: 78px;
  line-height: 62px;
  border-radius: 50%;
  border: 10px solid #CCDFFF;
  text-align: center;
  color: #fff;
  font-size: 40px;
  position: relative;
  z-index: 2;
  margin-bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-work-inner .count-inner:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 96px;
  width: 96px;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 15px rgba(0, 96, 255, 0.1);
  box-shadow: 0px 0px 15px rgba(0, 96, 255, 0.1);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 0;
  background: #E5EFFF;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-work-inner .details-wrap p {
  margin-bottom: 0;
}

.single-work-inner.style-two {
  background: #fff;
  padding: 0 30px 30px 30px;
  margin-top: 44px;
}

.single-work-inner.style-two .count-inner {
  margin-top: -47px;
  display: inline-block;
}

.single-work-inner.style-two:hover .count-inner h2 {
  background: var(--heading-color);
  border: 10px solid rgb(175, 192, 221);
}

.single-work-inner.style-two:hover .count-inner::after {
  background: rgb(215, 223, 237);
}

.single-work-inner.style-three .count-inner {
  background: var(--main-color);
  height: 90px;
  width: 90px;
  line-height: 98px;
  font-size: 40px;
  color: #fff;
  border-radius: 50%;
}

.single-work-inner.style-three .count-inner:after {
  display: none;
}

.single-work-inner.style-three:hover .count-inner {
  background: var(--heading-color);
}

.single-work-inner.style-four .count-inner h2 {
  border: 10px solid #327FFF;
}

.single-work-inner.style-four .count-inner:after {
  background: #4C8FFF;
}

.single-work-inner.style-four:hover .count-inner h2 {
  border: 10px solid #2d2b4b;
  background: var(--heading-color);
  color: var(--main-color);
}

.single-work-inner.style-four:hover .count-inner:after {
  background: #403d67;
}

.how-it-work-inner {
  position: relative;
}

.how-it-work-inner .hills-line {
  position: absolute;
  top: -9px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 70%;
}

.how-it-work-inner.arrow-line .single-work-inner:after {
  content: "";
  position: absolute;
  left: 100%;
  margin-left: -40px;
  top: 35px;
  /* background-image: url("/assets/img/shape/2.webp"); */
  height: 50px;
  width: 100%;
  background-repeat: no-repeat;
}

.how-it-work-inner.arrow-line .col-lg-2:last-child .single-work-inner:after,
.how-it-work-inner.arrow-line .col-lg-3:last-child .single-work-inner:after,
.how-it-work-inner.arrow-line .col-lg-4:last-child .single-work-inner:after,
.how-it-work-inner.arrow-line .col-lg-6:last-child .single-work-inner:after {
  display: none;
}

.client-slider .thumb img {
  width: auto !important;
  display: inline-block;
}

.client-slider .owl-nav {
  display: none;
}

.testimonial-thumb .thumb {
  position: relative;
}

.testimonial-thumb .thumb .quote-wrap {
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
}

.testimonial-thumb .thumb .quote-wrap .quote {
  position: relative;
  z-index: 3;
  height: 100px;
  width: 100px;
  line-height: 100px;
  background: #fff;
  display: inline-block;
}

.testimonial-thumb .thumb .quote-wrap img {
  display: inline-block;
}

.testimonial-thumb .thumb .quote-wrap:before {
  content: "";
  position: absolute;
  left: -6px;
  top: -6px;
  background: #026937;
  height: 40px;
  width: 40px;
  z-index: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.testimonial-thumb:hover .thumb {
  position: relative;
}

.testimonial-thumb:hover .thumb .quote-wrap:before {
  height: 70px;
  width: 70px;
}

.single-testimonial-inner {
  position: relative;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-testimonial-inner .details h2 {
  margin-bottom: 0;
}

.single-testimonial-inner .details p {
  margin-top: 30px;
  font-size: 24px;
  font-style: italic;
  text-align: justify;
  text-justify: inter-word;
}

.single-testimonial-inner.style-two .about-mask-bg-wrap {
  max-width: 150px;
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 20px;
}

.about-mask-bg-wrap .thumb img {
  max-width: 100%;
  max-height: 100%;
}

.about-mask-bg-wrap .thumb {
  display: flex;
  justify-content: center;
  /* Mengatur posisi horizontal */
  align-items: center;
  /* Mengatur posisi vertikal */
  height: 100%;
  /* Atur tinggi sesuai kebutuhan */
  width: 100%;
  /* Atur lebar sesuai kebutuhan */
}

.single-testimonial-inner.style-two .about-mask-bg-wrap .shape-image {
  position: absolute;
  z-index: 0;
  left: 2px;
  top: 20px;
  width: 97%;
}

.single-testimonial-inner.style-two .about-mask-bg-wrap .shape-image-sm {
  max-width: 40px;
  left: -8px;
}

.single-testimonial-inner.style-two .thumb {
  display: inline-block;
}

.single-testimonial-inner.style-two .thumb img {
  width: 150px;
}

.single-testimonial-inner.style-two .details p {
  margin-top: 10px;
  font-size: 20px;
}

.single-testimonial-inner.style-two:hover .about-mask-bg-wrap .thumb img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.single-testimonial-inner.style-3 {
  background: #F4F8FF;
  margin-top: 50px;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.single-testimonial-inner.style-3 .shaddow-img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: auto;
  -webkit-transform: translate(-50%, -35%);
  transform: translate(-50%, -35%);
}

.single-testimonial-inner.style-3 .thumb {
  display: inline-block;
  margin-top: -70px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: var(--main-color);
}

.single-testimonial-inner.style-3 .thumb img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-testimonial-inner.style-3 .details p {
  margin-top: 10px;
  font-size: 18px;
  font-style: normal;
  margin-bottom: 15px;
}

.single-testimonial-inner.style-3 .details h4 {
  margin-bottom: 0;
}

.single-testimonial-inner.style-3 .details span {
  color: var(--main-color);
}

.single-testimonial-inner.style-3:hover .thumb img {
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
}

.single-testimonial-inner.style-4 {
  margin-top: 50px;
  padding: 30px;
  margin-bottom: 30px;
}

.single-testimonial-inner.style-4:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  width: 60px;
  border-top: 1px solid var(--main-color);
  border-left: 1px solid var(--main-color);
}

.single-testimonial-inner.style-4:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  height: 60px;
  width: 60px;
  border-right: 1px solid var(--main-color);
  border-bottom: 1px solid var(--main-color);
}

.single-testimonial-inner.style-4 .shaddow-img {
  position: absolute;
  left: 70px;
  top: 60px;
  width: auto;
  -webkit-transform: translate(-50%, -35%);
  transform: translate(-50%, -35%);
  z-index: 0;
  max-width: 80px;
}

.single-testimonial-inner.style-4 .thumb {
  display: inline-block;
  margin-top: -70px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: var(--main-color);
}

.single-testimonial-inner.style-4 .thumb img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-testimonial-inner.style-4 .details {
  position: relative;
  z-index: 2;
}

.single-testimonial-inner.style-4 .details p {
  margin-top: 10px;
  font-size: 18px;
  font-style: normal;
  margin-bottom: 15px;
}

.single-testimonial-inner.style-4 .details h4 {
  margin-bottom: 0;
}

.single-testimonial-inner.style-4 .details span {
  color: var(--main-color);
}

.single-testimonial-inner.style-4:hover {
  background: var(--main-color);
}

.single-testimonial-inner.style-4:hover .thumb img {
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
}

.single-testimonial-inner.style-4:hover .details p {
  color: #fff;
}

.single-testimonial-inner.style-4:hover .details h4 {
  color: #fff;
}

.single-testimonial-inner.style-4:hover .details span {
  color: #fff;
}

.testimonial-nav-slider {
  width: 180px;
  height: 60px;
  position: absolute;
  left: 46%;
  bottom: 0;
}

.testimonial-nav-slider .slick-slide {
  width: 60px !important;
  height: 60px !important;
}

.testimonial-nav-slider .thumb img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.testimonial-nav-slider .slick-active {
  opacity: 0.4;
}

.testimonial-nav-slider .slick-active~.slick-active {
  opacity: 1;
}

.testimonial-nav-slider .slick-active~.slick-active .thumb img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.testimonial-nav-slider .slick-active~.slick-active~.slick-active {
  opacity: 0.4;
}

.testimonial-nav-slider .slick-active~.slick-active~.slick-active .thumb img {
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}

.testimonial-nav-slider .slick-prev,
.testimonial-nav-slider .slick-next,
.testimonial-nav-slider .slick-dots {
  display: none !important;
}

.testimonial-slider-bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 38.3%;
  background: #95D2B3;
}

.testimonial-slider-2 .owl-prev {
  position: absolute;
  left: -23px;
  top: 50%;
  visibility: hidden;
  opacity: 0;
  margin-top: -18px !important;
}

.testimonial-slider-2 .owl-next {
  position: absolute;
  right: -30px;
  top: 50%;
  visibility: hidden;
  opacity: 0;
  margin-top: -18px !important;
}

.testimonial-slider-2:hover .owl-prev {
  visibility: visible;
  opacity: 1;
}

.testimonial-slider-2:hover .owl-next {
  visibility: visible;
  opacity: 1;
}

.testimonial-slider-2.style-active-bg .center .single-testimonial-inner.style-4 {
  background: var(--main-color);
}

.testimonial-slider-2.style-active-bg .center .single-testimonial-inner.style-4 .shaddow-img {
  -webkit-filter: brightness(1);
  filter: brightness(1);
  z-index: 0;
  opacity: 0.3;
}

.testimonial-slider-2.style-active-bg .center .single-testimonial-inner.style-4 .details p {
  color: #fff;
}

.testimonial-slider-2.style-active-bg .center .single-testimonial-inner.style-4 .details h4 {
  color: #fff;
}

.testimonial-slider-2.style-active-bg .center .single-testimonial-inner.style-4 .details span {
  color: #fff;
}

.single-accordion-inner {
  background: #F4F8FF;
  border: 0 !important;
  margin-bottom: 25px;
  border-radius: 5px;
  overflow: hidden;
}

.single-accordion-inner .accordion-header {
  margin-bottom: 0 !important;
}

.single-accordion-inner .accordion-header button {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 18px;
  font-weight: 700;
  color: var(--heading-color);
  padding: 20px 30px;
}

.single-accordion-inner .accordion-body {
  padding: 20px 30px;
  padding-top: 0;
}

.single-accordion-inner.style-2 .accordion-header button {
  background: var(--main-color);
  color: #fff;
}

.single-accordion-inner.style-2 .accordion-header .collapsed {
  background: #F4F8FF;
  color: var(--heading-color);
}

.single-accordion-inner.style-2 .accordion-collapse.show {
  background: var(--main-color);
  color: #fff;
}

.single-project-inner {
  /* background: #F4F8FF; */
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
  width: fit-content;
}

.single-project-inner .thumb {
  position: relative;
  overflow: hidden;
  /* width: fit-content; */
}

.single-project-inner .thumb:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 96, 255, 0.6);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-project-inner .thumb img {
  -webkit-transition: 0.7s;
  transition: 0.7s;
}

.single-project-inner .thumb .icon {
  position: absolute;
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 52px;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  z-index: 3;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: var(--main-color);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-top: 40px;
}

.single-project-inner .details-wrap {
  padding: 20px 25px;
}

.single-project-inner .details-wrap .details-inner {
  border-left: 3px solid var(--main-color);
  padding-left: 15px;
}

.single-project-inner .details-wrap h3 {
  margin-bottom: 5px;
}

.single-project-inner .details-wrap p {
  margin-bottom: 0;
}

.single-project-inner .details-wrap a i,
.single-project-inner .details-wrap a svg {
  margin-left: 5px;
}

.single-project-inner:hover .thumb .icon {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
}

.single-project-inner:hover .thumb:after {
  visibility: visible;
  opacity: 1;
}

.single-project-inner:hover .thumb img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.single-project-inner:hover .details-wrap .details-inner {
  border-left: 3px solid var(--heading-color);
}

.single-project-inner.style-two .details-wrap {
  position: absolute;
  left: 0;
  bottom: -30px;
  width: 100%;
  background: rgba(0, 96, 255, 0.6);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-project-inner.style-two .details-wrap h3 {
  color: #fff;
  margin-bottom: 2px;
}

.single-project-inner.style-two .details-wrap h3:hover {
  color: var(--heading-color);
}

.single-project-inner.style-two .details-wrap p {
  color: #fff;
}

.single-project-inner.style-two .details-wrap a {
  color: #fff;
}

.single-project-inner.style-two .details-wrap a:hover {
  text-decoration: underline;
}

.single-project-inner.style-two:hover .details-wrap {
  visibility: visible;
  opacity: 1;
  bottom: 0;
}

.project-slider .owl-prev {
  position: absolute;
  left: -23px;
  top: 50%;
  margin-top: -100px !important;
  visibility: hidden;
  opacity: 0;
}

.project-slider .owl-next {
  position: absolute;
  right: -30px;
  top: 50%;
  margin-top: -100px !important;
  visibility: hidden;
  opacity: 0;
}

.project-slider:hover .owl-prev {
  visibility: visible;
  opacity: 1;
}

.project-slider:hover .owl-next {
  visibility: visible;
  opacity: 1;
}

.project-slider-2 .owl-nav {
  text-align: center;
  bottom: -30px;
  margin-top: 60px;
  position: relative;
}

.progress-item {
  margin-bottom: 20px;
}

.progress-item .progress-count-wrap {
  position: absolute;
  top: -35px;
  right: 16px;
}

.progress-item.add-progress-start .progress-count-wrap {
  position: absolute;
  top: -35px;
  right: -5px;
}

.progress-item.add-progress-full .progress-count-wrap {
  position: absolute;
  top: -35px;
  right: 11px;
}

.progress-item .progress-count.counting {
  font-weight: 600;
  font-size: 16px;
  color: var(--heading-color);
  font-family: var(--heading-font);
}

.progress-item .counting-icons {
  padding-top: 2px;
  padding-left: 2px;
  position: absolute;
  display: inline-block;
  font-weight: 600;
  font-size: 15px;
  color: var(--heading-color);
}

.progress-item .progress-bg {
  position: relative;
  height: 5px;
  border-radius: 30px;
  background: #7FAFFF;
}

.progress-item .progress-bg .progress-rate {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  border-radius: 30px;
  background: var(--main-color);
  -webkit-transition: 2.5s;
  transition: 2.5s;
}

.single-call-to-action-inner.style-white h5 {
  color: #fff;
}

.single-call-to-action-inner.style-white h2 {
  color: #fff;
}

.single-call-to-action-inner.style-white p {
  color: #fff;
}

.consulting-contact-form {
  -webkit-box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.05);
  box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.05);
  padding: 50px 50px 30px;
  background: #fff;
}

.single-pricing-inner {
  border: 2px solid var(--main-color);
  padding: 45px 30px 50px 30px;
  border-radius: 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.single-pricing-inner .icon-wrap {
  font-size: 75px;
  color: #fff;
  background: #004CCC;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  z-index: 2;
  margin-bottom: 10px;
}

.single-pricing-inner .icon-wrap i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.single-pricing-inner .header {
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}

.single-pricing-inner .header .price {
  position: relative;
}

.single-pricing-inner .header .price sup {
  font-size: 24px;
  top: -32px;
  font-weight: 600;
  color: var(--heading-color);
  font-family: var(--heading-font);
}

.single-pricing-inner .header .price h2 {
  font-size: 80px;
  font-weight: 700;
  line-height: 0.9;
  margin-bottom: 0;
}

.single-pricing-inner .header .price sub {
  font-size: 24px;
  font-weight: 600;
  font-family: var(--heading-font);
  line-height: 1;
  position: absolute;
  bottom: 12px;
  padding-left: 10px;
}

.single-pricing-inner .details .single-list-inner .close {
  opacity: 0.5;
}

.single-pricing-inner .details .btn {
  margin-top: 40px;
}

.single-pricing-inner:hover {
  -webkit-box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.05);
  box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.05);
}

.single-pricing-inner.style-1:hover {
  background: var(--main-color);
}

.single-pricing-inner.style-1:hover .header h3 {
  color: #fff;
}

.single-pricing-inner.style-1:hover .header .price sup {
  color: #fff;
}

.single-pricing-inner.style-1:hover .header .price h2 {
  color: #fff;
}

.single-pricing-inner.style-1:hover .header .price sub {
  color: #fff;
}

.single-pricing-inner.style-1:hover .details .single-list-inner li {
  color: #fff;
}

.single-pricing-inner.style-1:hover .details .single-list-inner li:after {
  background: #fff;
}

.single-pricing-inner.style-1:hover .details .btn {
  margin-top: 40px;
}

.single-pricing-inner.style-2 {
  position: relative;
  border: 0;
  -webkit-box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.05);
  box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.05);
  padding: 25px 30px 50px 30px;
}

.single-pricing-inner.style-2:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 180px;
  background: var(--main-color);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 52%, 50% 100%, 0 52%);
  clip-path: polygon(0 0, 100% 0, 100% 52%, 50% 100%, 0 52%);
}

.single-pricing-inner.style-2 .header {
  position: relative;
  z-index: 2;
  margin-bottom: 65px;
}

.single-pricing-inner.style-2 .header h3 {
  color: #fff;
}

.single-pricing-inner.style-2 .header .price sup {
  color: #fff;
}

.single-pricing-inner.style-2 .header .price h2 {
  color: #fff;
}

.single-pricing-inner.style-2 .header .price sub {
  color: #fff;
}

.single-pricing-inner.style-2 .details h4 {
  color: var(--paragraph-color);
  margin-bottom: 15px;
}

.single-pricing-inner.style-2:hover {
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

.single-pricing-inner.style-2:hover .btn {
  background: var(--main-color);
}

.single-pricing-inner.style-3 {
  background: var(--main-color);
  border: 0;
}

.single-pricing-inner.style-3:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 210px;
  background: #002666;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 76% 70%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 76% 70%, 0 100%);
  z-index: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-pricing-inner.style-3 .header h3 {
  color: #fff;
}

.single-pricing-inner.style-3 .header .price sup {
  color: #fff;
}

.single-pricing-inner.style-3 .header .price h2 {
  color: #fff;
}

.single-pricing-inner.style-3 .header .price sub {
  color: #fff;
}

.single-pricing-inner.style-3 .single-list-inner li {
  color: #fff;
  padding-left: 0;
}

.single-pricing-inner.style-3 .single-list-inner li:after {
  display: none;
}

.single-pricing-inner.style-3:hover {
  background: #002666;
  border: 0;
}

.single-pricing-inner.style-3:hover:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 210px;
  background: var(--main-color);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 76% 70%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 76% 70%, 0 100%);
  z-index: 0;
}

.single-pricing-inner.style-3:hover .btn {
  background: var(--main-color);
}

.project-isotope-btn button {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  font-size: 14px;
  font-weight: 600;
  background: transparent;
  margin: 0 3px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 4px;
  margin-bottom: 6px;
}

.project-isotope-btn button:first-child {
  margin-left: 0;
}

.project-isotope-btn button:last-child {
  margin-right: 0;
}

.project-isotope-btn button.active,
.project-isotope-btn button:hover {
  background: var(--main-color);
  color: #fff;
}

.project-isotope-btn.style-white button {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
}

.project-isotope-btn.style-white button.active,
.project-isotope-btn.style-white button:hover {
  background: var(--main-color);
  color: #fff;
  border: 1px solid var(--main-color);
}

.team-details-info h3 {
  margin-bottom: 0;
}

.team-details-info .designation {
  color: var(--main-color);
  margin-bottom: 20px;
}

.team-details-info .content {
  margin-bottom: 22px;
}

.team-details-info .details-info {
  margin: 0;
  padding: 0;
}

.team-details-info .details-info li {
  list-style: none;
  margin-bottom: 8px;
}

.team-details-info .details-info li:last-child {
  margin-bottom: 0;
}

.team-details-info .social-media {
  margin-top: 30px;
}

.team-details-info .social-media li a {
  border: 1px solid var(--main-color);
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 16px;
}

.single-skill-inner {
  padding: 30px;
}

.single-skill-inner h3 {
  margin-bottom: 15px;
}

.single-skill-inner .skill-list {
  margin-bottom: 30px;
}

.single-skill-inner .skill-list h6 {
  margin-bottom: 0;
}

.single-skill-inner .skill-list span {
  color: var(--main-color);
}

.single-skill-inner .skill-list p {
  margin-top: 10px;
  margin-bottom: 0;
}

.single-contact-inner {
  padding: 38px 30px 32px;
  -webkit-box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.05);
  box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.05);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 30px;
}

.single-contact-inner .thumb,
.single-contact-inner .icon-box {
  margin-bottom: 10px;
}

.single-contact-inner .details-inner h3 {
  margin-bottom: 5px;
}

.single-contact-inner .details-inner p {
  margin-bottom: 0;
}

.single-contact-inner:hover {
  -webkit-box-shadow: 0px 10px 30px rgba(0, 33, 71, 0.12);
  box-shadow: 0px 10px 30px rgba(0, 33, 71, 0.12);
}

.g-map-inner {
  margin-bottom: -10px;
  position: relative;
}

.g-map-inner iframe {
  width: 100%;
  height: 700px;
}

.g-map-contact {
  margin-top: -650px;
  position: relative;
  z-index: 3;
  margin-bottom: 50px;
}

.tab-button-style {
  margin: 0;
  padding: 0;
  border: 0;
}

.tab-button-style li {
  margin-right: 10px;
}

.tab-button-style li:last-child {
  margin-right: 0;
}

.tab-button-style li .nav-link {
  border: 0;
  background: #F4F8FF;
  border-radius: 5px;
  padding: 0 30px;
  height: 48px;
  line-height: 48px;
  font-weight: 600;
  color: var(--paragraph-color);
}

.tab-button-style li .nav-link.active {
  background: var(--main-color);
  color: #fff;
}

.testimonial-slider-3 .owl-nav {
  display: none;
}

.testimonial-slider-3-thumb {
  position: relative;
}

.testimonial-slider-3-thumb .owl-thumb-item {
  position: absolute;
  top: 0;
  height: 70px;
  width: 70px;
  padding: 0;
  border: 0;
  background: transparent;
}

.testimonial-slider-3-thumb .owl-thumb-item-1 {
  top: -390px;
  left: 0;
}

.testimonial-slider-3-thumb .owl-thumb-item-2 {
  top: -260px;
  left: 60px;
}

.testimonial-slider-3-thumb .owl-thumb-item-3 {
  top: -130px;
  left: 0;
}

.testimonial-slider-3-thumb .owl-thumb-item-4 {
  top: -390px;
  right: 0;
}

.testimonial-slider-3-thumb .owl-thumb-item-5 {
  top: -260px;
  right: 60px;
}

.testimonial-slider-3-thumb .owl-thumb-item-6 {
  top: -130px;
  right: 0;
}

.error-inner h2 {
  font-size: 80px;
  margin-bottom: 15px;
}

.error-inner p {
  margin-bottom: 24px;
}

.error-inner p span {
  color: var(--main-color);
}

.error-inner .btn {
  border-radius: 5px;
  padding: 0 50px;
}

.testimonial-thumb-2 .thumb {
  height: 100%;
  background-size: cover;
  min-height: 300px;
}

.testimonial-thumb-2 .single-testimonial-inner .details {
  padding-top: 120px;
  padding-bottom: 240px;
  max-width: 700px;
}

.testimonial-thumb-2 .single-testimonial-inner .details .thumb {
  height: 60px;
  width: 60px;
  min-height: 60px;
  border-radius: 50%;
  margin-bottom: 10px;
  display: inline-block;
  overflow: hidden;
}

.testimonial-thumb-2 .single-testimonial-inner .details p {
  font-size: 18px;
}

.testimonial-thumb-2 .thumb .quote-wrap {
  right: 0;
  left: auto;
  bottom: 34%;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  background: #fff;
  padding: 15px;
}

.testimonial-thumb-2 .thumb .quote-wrap:before {
  display: none;
}

.testimonial-thumb-2 .thumb .quote-wrap .quote {
  height: 60px;
  width: 60px;
  line-height: 30px;
  background: var(--main-color);
  padding: 15px;
}

.testimonial-nav-slider-2 {
  left: 57%;
  bottom: 120px;
}

.info-box-two .icon-box i {
  font-size: 44px;
  color: var(--main-color);
}

.section-title.bg-base.style-white .btn {
  padding: 0 20px;
}

/*---------------------------------------------------
	widget styles
----------------------------------------------------*/
.widget-title {
  position: relative;
  font-size: 20px;
}

.td-sidebar .widget {
  margin-bottom: 34px;
  padding: 30px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.td-sidebar .widget .widget-title {
  margin-bottom: 30px;
  font-size: 24px;
  position: relative;
  padding-bottom: 8px;
}

.td-sidebar .widget .widget-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 3px;
  width: 100%;
  background: #E1E1E1;
}

.td-sidebar .widget .widget-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 3px;
  width: 60px;
  background: var(--main-color);
  z-index: 2;
}

.td-service-sidebar .widget {
  margin-bottom: 34px;
  border-radius: 4px;
  background: #FAFAFA;
  border: 0;
}

.td-service-sidebar .widget .widget-title {
  margin-bottom: 0px;
  font-size: 24px;
  position: relative;
  padding-bottom: 0;
  padding: 10px 20px;
  background: var(--main-color);
  color: #fff;
}

.td-service-sidebar .widget.widget_search {
  overflow: hidden;
}

.td-service-sidebar .widget.widget_search .search-form {
  margin: 25px;
}

.td-service-sidebar .widget.widget_catagory ul {
  padding: 25px;
}

.td-service-sidebar .widget.widget_catagory ul li a {
  padding-left: 0;
}

.td-service-sidebar .widget.widget_catagory ul li a:after {
  display: none;
}

.td-service-sidebar .widget.widget_archive ul {
  padding: 25px;
}

.td-service-sidebar .widget.widget_archive ul li a {
  padding-left: 0;
}

.td-service-sidebar .widget.widget_archive ul li a:after {
  display: none;
}

.td-service-sidebar .widget.widget-recent-post ul {
  padding: 25px;
}

.td-service-sidebar .widget.widget_tag_cloud .tagcloud {
  padding: 25px;
}

.td-service-sidebar .widget.widget_tag_cloud .tagcloud a {
  background: #e9ebef;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.td-service-sidebar .widget.widget_tag_cloud .tagcloud a:hover {
  background: var(--main-color);
}

.footer-area .widget-title {
  color: #fff;
}

.td-sidebar-service .widget {
  margin-bottom: 34px;
  padding: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.td-sidebar-service .widget .widget-title:before {
  display: none;
}

.td-sidebar-service .widget .widget-title:after {
  border-bottom: 1px dashed var(--main-color);
  background: transparent;
  width: 156px;
}

.widget_search .search-form {
  position: relative;
  background: #F4F6F9;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #E1E1E1;
}

.widget_search .search-form .form-group {
  margin-bottom: 0;
}

.widget_search .search-form input {
  width: 100%;
  border: 0;
  height: 55px;
  padding: 0 60px 0 22px;
}

.widget_search .search-form input:focus {
  border: 0;
  outline: 0;
}

.widget_search .search-form button {
  position: absolute;
  right: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0 22px;
  top: 0;
  height: 55px;
}

.widget_search .search-form button i {
  color: var(--heading-color);
}

.widget_search .search-form button:active,
.widget_search .search-form button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

.widget-recent-post ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 9px;
  padding-bottom: 0;
}

.widget-recent-post ul li {
  margin-bottom: 15px;
}

.widget-recent-post ul li .media {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.widget-recent-post ul li .media .media-left {
  margin-right: 18px;
}

.widget-recent-post ul li .media .media-left img {
  border-radius: 4px;
}

.widget-recent-post ul li .media .media-body .title {
  margin-bottom: 8px;
}

.widget-recent-post ul li .media .media-body .post-info {
  font-size: 13px;
  color: var(--heading-color);
}

.widget-recent-post ul li .media .media-body .post-info span {
  color: #3F517E;
}

.widget-recent-post ul li .media .media-body .post-info svg,
.widget-recent-post ul li .media .media-body .post-info i {
  margin-right: 5px;
  color: var(--main-color);
}

.widget-recent-post ul li:last-child {
  border-bottom: 0 !important;
  padding-bottom: 0;
  margin-bottom: 0;
}

.widget_catagory ul {
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: -5px;
}

.widget_catagory ul li {
  list-style: none;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(66, 74, 97, 0.1);
}

.widget_catagory ul li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.widget_catagory ul li a {
  position: relative;
  padding-left: 26px;
}

.widget_catagory ul li a span {
  float: right;
}

.widget_catagory ul li a i {
  margin-right: 5px;
}

.widget_catagory ul li a svg {
  margin-right: 5px;
}

.widget_catagory ul li a:after {
  content: "\f07c";
  position: absolute;
  left: 0;
  top: -3px;
  font-family: "Font Awesome 5 Free";
}

.widget_catagory ul li:last-child {
  margin-bottom: 0;
}

.widget_archive ul {
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top: -5px;
}

.widget_archive ul li {
  list-style: none;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(66, 74, 97, 0.1);
}

.widget_archive ul li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.widget_archive ul li a {
  position: relative;
  padding-left: 26px;
}

.widget_archive ul li a span {
  float: right;
}

.widget_archive ul li a i {
  margin-right: 5px;
}

.widget_archive ul li a svg {
  margin-right: 5px;
}

.widget_archive ul li a:after {
  content: "\f07c";
  position: absolute;
  left: 0;
  top: -3px;
  font-family: "Font Awesome 5 Free";
}

.widget_archive ul li:last-child {
  margin-bottom: 0;
}

.widget_catagory_2 ul {
  padding: 0;
}

.widget_catagory_2 ul li {
  list-style: none;
}

.widget_catagory_2 ul li a {
  border: 1px solid #D1D1D1;
  display: block;
  margin-bottom: 20px;
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: 600;
}

.widget_catagory_2 ul li a i {
  float: right;
  padding-top: 3px;
  font-size: 21px;
}

.widget_catagory_2 ul li a span i {
  float: left;
  margin-right: 10px;
  color: var(--main-color);
  background: #FFE6E2;
  padding: 5px 5px 2px 5px;
  border-radius: 3px;
}

.widget_catagory_2 ul li a:hover {
  background-image: -webkit-gradient(linear, left top, right top, from(#F99746), to(#EE0020));
  background-image: linear-gradient(to right, #F99746, #EE0020);
  border: 1px solid var(--main-color);
  color: #fff;
}

.widget_tag_cloud .tagcloud a {
  background: #F4F6F9;
  height: 36px;
  line-height: 36px;
  padding: 0 18px;
  border-radius: 36px;
  display: inline-block;
  margin: 0 5px 9px 0;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #909090;
}

.widget_tag_cloud .tagcloud a i {
  float: right;
  padding-top: 5px;
}

.widget_tag_cloud .tagcloud a:hover {
  background: var(--main-color);
  color: #ffffff;
  border-color: var(--main-color);
}

/*********** widget_checkbox_list ************/
.widget_checkbox_list .single-checkbox:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.single-checkbox {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 7px;
  padding-bottom: 7px;
  cursor: pointer;
  font-size: 16px;
  color: var(--heading-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom: 1px solid #E3E3E3;
}

.single-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.single-checkbox input:checked~.checkmark {
  background-color: var(--main-color);
}

.single-checkbox input:checked~.checkmark:after {
  display: block;
}

.single-checkbox .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #EAE1D6;
}

.single-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/******** widget-video-inner *********/
.widget-video-inner {
  border-radius: 5px;
}

.widget-video-inner .thumb {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.widget-video-inner .thumb:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

.widget-video-inner .thumb .video-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  line-height: 52px;
  z-index: 1;
}

.widget-video-inner .thumb .video-btn i {
  font-size: 18px;
}

.widget-video-inner .thumb .video-btn:after {
  width: 70px;
  height: 70px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.widget-video-inner .details {
  margin-top: 10px;
}

.widget-video-inner .details ul {
  padding: 0;
  margin: 0;
}

.widget-video-inner .details ul li {
  list-style: none;
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  border-bottom: 1px dashed #E3E3E3;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.widget-video-inner .details ul li:last-child {
  border-bottom: 0;
}

.widget-video-inner .details ul li i {
  color: var(--main-color);
  margin-right: 7px;
}

.widget-video-inner .details ul li span {
  margin-right: auto;
  font-weight: 500;
  color: var(--heading-color);
}

/******** widget-contact *********/
.widget_contact .details {
  padding: 0;
}

.widget_contact .details li {
  margin-bottom: 18px;
  position: relative;
  list-style: none;
}

.widget_contact .details li i {
  margin-right: 14px;
  color: var(--main-color2);
}

.widget_contact .details li:last-child {
  margin-bottom: 0;
}

.widget_contact .details li .time {
  font-size: 12px;
  margin-top: 3px;
}

.widget_contact .social-media li a {
  color: var(--main-color);
}

.widget_contact .social-media li a:hover {
  background: var(--main-color);
  color: #fff;
}

.widget_instagram .instagram-wrap {
  margin: 0 10px;
}

.widget_instagram .instagram-wrap .thumb {
  margin-bottom: 10px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
}

.widget_instagram .instagram-wrap .thumb img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.widget_instagram .instagram-wrap .thumb:after {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.widget_instagram .instagram-wrap .thumb i {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  background: rgba(253, 74, 54, 0.7);
  display: inline-block;
  z-index: 3;
  color: #fff;
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.widget_instagram .instagram-wrap .thumb:hover:after {
  visibility: visible;
  opacity: 1;
}

.widget_instagram .instagram-wrap .thumb:hover i {
  visibility: visible;
  opacity: 1;
}

.widget_instagram .instagram-wrap .thumb:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.widget-project-info {
  background: #FAFAFA;
  padding: 50px 40px;
}

.widget-project-info ul {
  margin: 0;
  padding: 0;
}

.widget-project-info ul li {
  list-style: none;
  margin-top: 0;
  margin-bottom: 15px;
}

.widget-project-info ul li h6 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 18px;
}

.widget-project-info ul li p {
  margin-bottom: 0;
}

.widget-project-info ul li:last-child {
  margin-bottom: 0;
}

/*------------------------------------------------
    blog style
------------------------------------------------*/
.single-blog-inner {
  margin-bottom: 60px;
  overflow: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 22px;
}

.single-blog-inner .share-area-wrap {
  position: relative;
}

.single-blog-inner .share-area-wrap:hover .share-hover-icons {
  visibility: visible;
  opacity: 1;
}

.single-blog-inner .share-wrap {
  cursor: pointer;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-blog-inner .share-wrap.active {
  color: var(--main-color);
}

.single-blog-inner .share-hover-icons {
  display: inline-block;
  background-color: var(--main-color);
  padding: 3px 6px 2px 6px;
  width: auto;
  position: absolute;
  border-radius: 3px;
  right: 0;
  top: -40px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-blog-inner .share-hover-icons ul {
  margin: 0;
  padding: 0;
}

.single-blog-inner .share-hover-icons ul li {
  display: inline-block;
  list-style: none;
}

.single-blog-inner .share-hover-icons ul li a {
  color: #fff;
  padding: 0 2px;
  font-size: 13px;
}

.single-blog-inner .share-hover-icons ul li a:hover {
  color: var(--heading-color);
}

.single-blog-inner .share-hover-icons.active {
  visibility: visible;
  opacity: 1;
}

.single-blog-inner .share-hover-icons:after {
  content: "";
  position: absolute;
  right: 5px;
  bottom: -4px;
  -webkit-transform: rotate(53deg);
  transform: rotate(53deg);
  height: 11px;
  width: 10px;
  background: var(--main-color);
}

.single-blog-inner .blog-thumb-slider {
  margin-bottom: 20px;
}

.single-blog-inner .thumb {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.single-blog-inner .thumb .video-play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.single-blog-inner .thumb img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.single-blog-inner .thumb .blog-meta {
  background: var(--main-color3);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
  margin: 0 30px;
  padding: 7px 15px;
}

.single-blog-inner .thumb .blog-meta li {
  color: #fff;
}

.single-blog-inner .thumb .blog-meta li svg {
  color: #fff;
}

.single-blog-inner .blog-meta {
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
}

.single-blog-inner .blog-meta li {
  display: inline-block;
  margin-right: 12px;
  color: #8A8A8A;
  font-size: 14px;
}

.single-blog-inner .blog-meta li i {
  margin-right: 4px;
  color: var(--main-color);
}

.single-blog-inner .blog-meta li svg {
  margin-right: 4px;
  color: var(--main-color);
}

.single-blog-inner .blog-meta li:last-child {
  margin-right: 0;
}

.single-blog-inner .details {
  position: relative;
}

.single-blog-inner .details h2 {
  margin-bottom: 3px;
}

.single-blog-inner .details h2:hover a {
  color: var(--main-color);
}

.single-blog-inner .details h4 {
  line-height: 1.1;
}

.single-blog-inner .details .title {
  margin-bottom: 17px;
}

.single-blog-inner .details p {
  margin-bottom: 16px;
}

.single-blog-inner .details .read-more-text {
  font-weight: 600;
}

.single-blog-inner .details .read-more-btn {
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  background: var(--main-color3);
  display: inline-block;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: -20px;
  margin-left: -23px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-blog-inner .portfolio-details {
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--main-color);
  border-radius: 0 30px 0 0;
  overflow: hidden;
}

.single-blog-inner .portfolio-details h5 {
  padding: 10px 30px;
  background: var(--heading-color);
  color: #fff;
}

.single-blog-inner .portfolio-details p {
  margin-bottom: 4px;
  color: #fff;
  border-bottom: 1px dashed #fff;
  padding: 5px 30px 7px 30px;
}

.single-blog-inner .portfolio-details p:last-child {
  border-bottom: 0;
}

.single-blog-inner .portfolio-details strong {
  min-width: 95px;
  display: inline-block;
  color: #fff;
}

.single-blog-inner:hover .thumb img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.single-blog-inner.style-2 {
  background: #F4F8FF;
  border-bottom: 0;
}

.single-blog-inner.style-2 .details {
  padding: 0 30px;
}

.single-blog-inner.style-3 {
  border-bottom: 0;
  margin-bottom: 60px;
}

.single-blog-inner.style-3 .thumb {
  margin-bottom: 0;
}

.single-blog-inner.style-3 .details {
  background: #F4F8FF;
  padding: 20px 30px 30px 30px;
  margin: 0 30px;
}

.single-blog-inner.style-3:hover .details .read-more-btn {
  background: var(--heading-color);
}

.single-blog-inner.style-4 {
  border-bottom: 0;
}

.single-blog-inner.style-4 .thumb {
  position: relative;
}

.single-blog-inner.style-4 .thumb .date {
  background: var(--main-color);
  position: absolute;
  left: 0;
  bottom: 0;
  height: 74px;
  width: 76px;
  border-radius: 0 40px 0 0;
  font-size: 24px;
  color: #fff;
  line-height: 0.8;
  padding-top: 22px;
  text-align: center;
}

.single-blog-inner.style-4 .thumb .date span {
  font-size: 18px;
}

.single-blog-inner.style-4 .details {
  padding: 0;
}

.single-blog-inner.style-4 .bottom {
  padding: 8px 0;
  border-top: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
}

.blog-thumb-slider .owl-prev {
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -20px !important;
  margin-right: 0 !important;
}

.blog-thumb-slider .owl-next {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -20px !important;
  margin-right: 0 !important;
}

/************ page navigation ************/
.pagination {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 10px 0 0 0;
}

.pagination .prev,
.pagination .next {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.pagination .prev:hover,
.pagination .next:hover {
  border: 1px solid var(--main-color);
}

.pagination .page-numbers {
  height: 50px;
  width: 50px;
  margin-right: 8px;
  border: 0;
  border-radius: 50%;
  border: 1px solid #616161;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  display: block;
  color: #616161;
  font-size: 18px;
}

.pagination .page-numbers:active,
.pagination .page-numbers:focus,
.pagination .page-numbers:hover {
  background: var(--main-color);
  color: #fff;
}

.pagination .page-numbers.current {
  background: var(--main-color);
  color: #FFF;
  border: 1px solid var(--main-color);
}

/******** blog-details-page-content ***********/
.blog-details-page-content .single-blog-inner {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.blog-details-page-content blockquote {
  background: #FAFAFA;
  padding: 30px 40px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 30px;
  font-style: italic;
  position: relative;
  text-align: center;
}

.blog-details-page-content blockquote p {
  margin-bottom: 0 !important;
  font-weight: 300;
}

.blog-details-page-content blockquote img {
  position: absolute;
  top: 30px;
  left: 30px;
}

.blog-details-page-content blockquote i {
  font-size: 35px;
  color: var(--main-color);
}

/*********** tag-and-share ************/
.tag-and-share {
  margin-top: 50px;
  border-top: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  padding: 15px 0;
}

.tag-and-share .tags a {
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  background: #F2F5F6;
  display: inline-block;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
  color: #757575;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.tag-and-share .tags a:hover {
  background: var(--main-color);
  color: #fff;
}

.tag-and-share strong {
  color: var(--heading-color);
  margin-right: 6px;
  font-size: 20px;
}

.tag-and-share .blog-share ul {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.tag-and-share .blog-share ul li {
  display: inline-block;
  list-style: none;
  margin-right: 10px;
  color: var(--heading-color);
}

.tag-and-share .blog-share ul li:last-child {
  margin-right: 0;
}

/****** prev-next-post ******/
.prev-next-post {
  padding-top: 20px;
  margin-top: 30px;
}

.blog-comment-form {
  padding-top: 30px;
}

.blog-comment-form h3 {
  position: relative;
  margin-bottom: 32px;
}

.comment-content a {
  word-wrap: break-word;
}

.bypostauthor {
  display: block;
}

.blog-comment {
  position: relative;
  margin-top: 30px;
}

.blog-comment .comment-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.blog-comment .comment-list ul.children {
  margin: 0;
  list-style: none;
  padding-left: 65px;
}

.blog-comment .comment-box {
  position: relative;
  margin-bottom: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid var(--aivons-light, #f2f4f8);
}

.blog-comment .comment {
  position: relative;
  min-height: 90px;
}

.blog-comment .comment-box .author-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-bottom: 20px;
  overflow: hidden;
}

.blog-comment .comment-box .author-thumb img {
  width: 90px;
  height: 90px;
  display: block;
  border-radius: 50%;
}

.blog-comment .comment-box .info {
  position: relative;
  margin-bottom: 25px;
  line-height: 24px;
}

.blog-comment .comment-box .info .name {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 30px;
  font-weight: 800;
  color: var(--aivons-black, #0f0d1d);
  text-transform: uppercase;
}

.blog-comment .comment-box .info .date {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: var(--main-color);
}

.blog-comment .comment-box .text {
  position: relative;
  display: block;
  color: var(--aivons-gray, #726f84);
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
}

.blog-comment .comment-box .reply-btn {
  position: relative;
  display: block;
}

.blog-comment .comment-box .theme-btn .btn-title {
  padding: 6px 30px 4px;
  line-height: 30px;
}

.blog-comment .comment-body .says {
  display: none;
}

.blog-comment .comment-body {
  position: relative;
  padding-left: 65px;
  margin-bottom: 30px;
}

.blog-comment .pingback .comment-body,
.blog-comment .trackback .comment-body {
  padding-left: 0;
}

.blog-comment .comment-body .avatar {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  height: 45px;
  width: 45px;
}

.blog-comment .fn a,
.blog-comment .fn {
  position: relative;
  display: block;
  font-size: 18px;
  color: var(--heading-color);
  text-decoration: none;
  line-height: 1;
}

.blog-comment .comment-body .comment-metadata {
  margin-bottom: 13px;
}

.blog-comment .comment-body .comment-metadata a {
  position: relative;
  display: inline-block;
  font-size: 12px;
  text-decoration: none;
}

.blog-comment .comment-body .comment-metadata a:hover {
  color: var(--aivons-black, #0f0d1d);
}

.blog-comment .comment-body .comment-metadata .edit-link a::before {
  content: ".";
  margin-left: 8px;
  margin-right: 10px;
}

.blog-comment .comment-content p {
  position: relative;
  display: block;
  color: var(--aivons-gray, #726f84);
  font-weight: 300;
  line-height: 1.5em;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 2em;
  font-family: var(--aivons-font, "Jost", sans-serif);
  letter-spacing: 0;
  font-weight: 500;
}

.blog-comment .comment-content table a {
  color: var(--main-color);
  text-decoration: none;
}

.blog-comment .comment-content {
  font-weight: 500;
}

.blog-comment .comment-content table a {
  color: var(--aivons-black, #0f0d1d);
}

.blog-comment .comment-content table a:hover {
  color: var(--main-color);
}

.blog-comment .comment-content p:last-of-type {
  margin-bottom: 0;
}

.blog-comment .reply a {
  position: relative;
  background-color: var(--main-color);
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  height: 30px;
  line-height: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-top: 40px;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  border: none;
  outline: none !important;
  padding: 0 18px;
  border-radius: 30px;
  text-transform: capitalize;
}

@media (min-width: 992px) {
  .blog-comment .reply a {
    margin-top: 0;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.blog-comment .reply a:hover {
  background-color: var(--aivons-black, #0f0d1d);
  color: #ffffff;
}

.blog-comment .blog-pagination a,
.blog-comment .blog-pagination span {
  margin-bottom: 45px;
}

.comment-respond .blog-details__content-title {
  margin-bottom: 50px;
}

.reply-form .logged-in-as {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: var(--main-color);
  margin-left: -10px;
  margin-top: -40px;
  margin-bottom: 20px;
}

.reply-form .logged-in-as a {
  text-decoration: none;
  margin-left: 10px;
  margin-right: 10px;
}

.comment-form__title small {
  font-size: 20px;
}

.comment-form__title small a {
  text-decoration: none;
  color: var(--main-color);
  text-decoration: underline;
}

.comment-form__title small a:hover {
  color: var(--aivons-black, #0f0d1d);
  opacity: 0.5;
}

.comment-list .comment-respond {
  margin-bottom: 30px;
}

.reply-form .form-submit {
  margin-bottom: 0;
}

.comment-list .reply-form .form-submit {
  margin-bottom: 30px;
}

.shape-left-top {
  position: absolute;
  left: 0;
  top: 100px;
  max-width: 150px;
}

.shape-right-top {
  position: absolute;
  right: 0;
  top: 100px;
  max-width: 150px;
}

.shape-left-center {
  position: absolute;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 150px;
}

.shape-right-center {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 150px;
}

/******* banner masking ********/
.banner-mask-bg-wrap {
  position: relative;
}

.banner-mask-bg-wrap .thumb {
  /* -webkit-mask-image: url(../../assets/img/banner/1.webp);
  mask-image: url(../../assets/img/banner/1.webp); */
}

.banner-mask-bg-wrap .thumb {
  position: relative;
  display: block;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: 100% 100%;
  -webkit-mask-position: center;
  mask-position: center;
  overflow: hidden;
}

.banner-mask-bg-wrap .thumb img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.banner-mask-bg-wrap .thumb:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.banner-mask-bg-wrap .shape-image {
  position: absolute;
  z-index: 0;
  left: 32px;
  top: -63px;
}

/******* about masking ********/
.about-mask-bg-wrap {
  position: relative;
}

.about-mask-bg-wrap-1 .thumb {
  /* -webkit-mask-image: url(../../assets/img/about/1m.webp);
  mask-image: url(../../assets/img/about/1m.webp); */
}

.about-mask-bg-wrap .thumb {
  position: relative;
  display: block;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: 100% 100%;
  -webkit-mask-position: center center;
  mask-position: center center;
  overflow: hidden;
}

.about-mask-bg-wrap .thumb img {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.about-mask-bg-wrap:hover .thumb img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.about-mask-bg-wrap-1 .shape-image-sm {
  position: absolute;
  left: -20px;
  top: -10px;
}

.about-mask-bg-wrap-1 .shape-image {
  position: absolute;
  z-index: 0;
  left: 22px;
  top: 1px;
  width: 90%;
}

.about-mask-bg-wrap-2 .thumb {
  /* -webkit-mask-image: url(../../assets/img/about/2m.webp);
  mask-image: url(../../assets/img/about/2m.webp); */
}

.about-mask-bg-wrap-2:after {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 16px;
  margin-left: 4px;
  height: 280px;
  width: 280px;
  border-radius: 50%;
  border: 10px solid #fff;
}

.about-mask-bg-wrap-2 .shape-image-sm {
  position: absolute;
  right: 5px;
  bottom: -10px;
}

.about-mask-bg-wrap-2 .shape-image {
  position: absolute;
  z-index: 0;
  right: -15px;
  top: 10px;
  width: 93%;
}

.about-mask-bg-wrap-3 .thumb {
  /* -webkit-mask-image: url(../../assets/img/about/1s.webp);
  mask-image: url(../../assets/img/about/1s.webp); */
}

.about-mask-bg-wrap-4 .thumb {
  /* -webkit-mask-image: url(../../assets/img/about/4m.webp);
  mask-image: url(../../assets/img/about/4m.webp); */
}

.about-mask-bg-wrap-4 .shape-image-sm {
  position: absolute;
  left: 25px;
  bottom: 0;
}

.about-mask-bg-wrap-4 .shape-image {
  position: absolute;
  z-index: 2;
  left: 24px;
  top: -2px;
  width: 91%;
}

/******* faq-image-wrap ********/
.faq-image-wrap {
  position: relative;
}

.faq-image-wrap .img-position-1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.faq-image-wrap .img-position-2 {
  position: absolute;
  left: 115px;
  top: 50px;
  z-index: -1;
}

.home-help-desk {
  --main-color: #0066FF;
  --heading-font: "Poppins", sans-serif;
}

.navbar-area-4 .nav-container {
  border-bottom: none;
}

.navbar-area-4 .nav-right-part .btn {
  height: 42px;
  line-height: 40px;
  border-radius: 40px !important;
  padding: 0 20px;
}

.banner-area-4 .bg-cover {
  background-size: 100% 100% !important;
}

.banner-area-4 .banner-inner {
  padding: 250px 0 250px 0;
}

.banner-area-4 .banner-inner .title {
  position: relative;
  font-size: 65px;
}

.banner-area-4 .banner-inner .title .title-line {
  position: absolute;
  left: 0;
  top: 57px;
  width: 98%;
  z-index: -1;
}

.banner-area-4 .thumb {
  margin-bottom: -250px;
  margin-right: -100px;
}

.single-intro-inner.style-two {
  padding: 60px;
  border-radius: 8px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.single-intro-inner.style-two .thumb h4 {
  font-weight: 700;
}

.single-intro-inner.style-two .details p {
  color: rgba(0, 40, 78, 0.7);
}

.single-intro-inner.style-two:hover {
  background: #E2F5FF;
}

.section-title .title {
  position: relative;
}

.section-title .title span {
  z-index: 2;
  position: relative;
}

.section-title .title span img {
  z-index: -1;
}

.section-title .title img {
  position: absolute;
  left: 0;
  top: 33px;
  z-index: 0;
}

.section-title .bottom-line-url {
  display: inline-block;
  margin-bottom: 0;
  border-bottom: 1px solid #738BAE;
  color: #738BAE;
}

.section-title .bottom-line-url a {
  font-weight: 700;
  color: var(--heading-color);
}

.single-choose-inner .media-left {
  position: relative;
}

.single-choose-inner .media-left .left-arrow-img {
  position: absolute;
  left: 21px;
  top: 58px;
  height: 56px;
}

.single-choose-inner .icon-number {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  line-height: 45px;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  text-align: center;
  margin-right: 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-choose-inner:hover .icon-number {
  background: var(--main-color);
  color: #fff;
}

.single-team-inner.style-4 {
  background: transparent;
  border-bottom: 1px solid var(--main-color);
}

.single-testimonial-inner.style-three .thumb img {
  width: auto;
  display: inline-block;
}

.single-testimonial-inner.style-three .details h2,
.single-testimonial-inner.style-three .details span,
.single-testimonial-inner.style-three .details p {
  color: #fff;
}

.single-testimonial-inner.style-three .details h2 {
  font-size: 20px;
  font-weight: 400;
  padding-top: 10px;
}

.single-testimonial-inner.style-three .details span {
  font-size: 14px;
}

.counter-area-2 {
  border-radius: 10px;
  margin-bottom: -100px;
  background: #fff;
  padding: 50px 50px 10px 50px;
  position: relative;
  -webkit-box-shadow: 5px 3px 20px rgba(0, 73, 171, 0.05);
  box-shadow: 5px 3px 20px rgba(0, 73, 171, 0.05);
}

.single-exp-inner.style-4 h5 {
  font-weight: 400;
  font-size: 16px;
  color: #00284E;
  margin-top: 10px;
}

.single-blog-inner.style-4 {
  background: #F4FBFF;
}

.single-blog-inner.style-4 .details {
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 0;
}

.single-blog-inner.style-4 .details .date {
  position: absolute;
  top: -40px;
  left: 30px;
  background: var(--main-color);
  padding: 5px 15px;
  color: #fff;
  border-radius: 40px;
}

.single-blog-inner.style-4 .details h3 {
  line-height: 1.3;
}

.single-blog-inner.style-4 .details .read-more-text span {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.single-blog-inner.style-4 .details .read-more-text:after {
  visibility: visible;
  opacity: 1;
  width: 100%;
  height: 10px;
  border-radius: 6px;
  z-index: 0;
}

.single-blog-inner.style-4 .details .read-more-text:hover:after {
  height: 1px;
}

.single-pricing-inner {
  margin-bottom: 65px;
}

.single-pricing-inner.style-4 {
  position: relative;
  padding: 62px 30px 0px;
  border: 1px solid rgba(0, 102, 255, 0.38);
  border-radius: 25px;
  overflow: inherit;
}

.single-pricing-inner.style-4 .header {
  position: relative;
  z-index: 2;
  margin-bottom: 0px;
}

.single-pricing-inner.style-4 .header h3 {
  font-weight: 500;
  font-size: 28px;
  color: #292929;
  margin: 30px 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.single-pricing-inner.style-4 .header .price {
  margin-bottom: 27px;
}

.single-pricing-inner.style-4 .header .price h2 {
  font-weight: 600;
  font-size: 60px;
  color: #3840F7;
  display: inline-block;
  margin-bottom: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.single-pricing-inner.style-4 .header .price sub {
  color: #3840F7;
  font-weight: 600;
  font-size: 30px;
  padding: 0;
  margin: 0 0 -5px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.single-pricing-inner.style-4 .details .single-list-inner {
  margin-bottom: 60px;
}

.single-pricing-inner.style-4 .details .single-list-inner li {
  padding: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 47px;
  color: #222429;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.single-pricing-inner.style-4 .details .single-list-inner li:after {
  display: none;
}

.single-pricing-inner.style-4 .details .btn {
  border: 2px solid #0066FF;
  border-radius: 34px;
  height: 70px;
  line-height: 68px;
  background: #fff;
  font-weight: 500;
  font-size: 20px;
  color: #292929;
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
  margin-top: -35px;
}

.single-pricing-inner.style-4:hover {
  background: #3840F7;
}

.single-pricing-inner.style-4:hover .details .single-list-inner li,
.single-pricing-inner.style-4:hover h3 {
  color: #fff;
}

.single-pricing-inner.style-4:hover .price sub,
.single-pricing-inner.style-4:hover .price h2 {
  color: #fff;
}

.single-pricing-inner.style-4:hover .btn {
  background: #3840F7;
  color: #fff;
  border-color: #fff;
}

.footer-area-3 {
  padding-top: 200px !important;
}

.footer-area-3 .widget_nav_menu ul li:after {
  background: #fff !important;
}

.footer-area-3 .widget_nav_menu ul li:hover:after {
  background: var(--heading-color) !important;
}

.footer-area-3 .widget_nav_menu ul li:hover a {
  color: var(--heading-color) !important;
}

.footer-area-3 .widget-recent-post ul li .media .media-left img {
  border-radius: 50%;
}

.footer-area-3 .widget-recent-post ul li .media .media-body .title a {
  color: #fff;
}

.footer-area-3 .widget-recent-post ul li .media .media-body .title:hover a {
  color: var(--heading-color);
}

.footer-area-3 .widget-recent-post ul li .media .media-body .post-info span,
.footer-area-3 .widget-recent-post ul li .media .media-body .post-info svg,
.footer-area-3 .widget-recent-post ul li .media .media-body .post-info i {
  color: #fff !important;
}

.footer-gallery-img {
  margin: 0;
  padding: 0;
}

.footer-gallery-img li {
  list-style: none;
  display: inline-block;
  margin: 5px 3px;
  width: 30%;
}

.subscribe-area-4 {
  background: #FFFFFF;
  border-radius: 25px;
  -webkit-box-shadow: 0px 30px 80px rgba(0, 73, 171, 0.1);
  box-shadow: 0px 30px 80px rgba(0, 73, 171, 0.1);
  padding: 47px 0px 47px 100px;
  -webkit-transform: translate(0px, 50%);
  transform: translate(0px, 50%);
  position: relative;
  z-index: 1;
}

.subscribe-area-4 h4 {
  font-weight: 600;
  font-size: 24px;
  color: #292929;
}

.subscribe-area-4 .single-subscribe-inner-4 {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding-right: 100px;
  margin-right: 70px;
}

.subscribe-area-4 .single-input-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  margin-top: 17px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.subscribe-area-4 .single-input-inner input {
  height: 60px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
}

.subscribe-area-4 .single-input-inner input::-webkit-input-placeholder {
  color: #4F4F4F;
}

.subscribe-area-4 .single-input-inner input::-moz-placeholder {
  color: #4F4F4F;
}

.subscribe-area-4 .single-input-inner input:-ms-input-placeholder {
  color: #4F4F4F;
}

.subscribe-area-4 .single-input-inner input::-ms-input-placeholder {
  color: #4F4F4F;
}

.subscribe-area-4 .single-input-inner input::placeholder {
  color: #4F4F4F;
}

.subscribe-area-4 .single-input-inner button {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  height: 60px;
  line-height: 60px;
  font-weight: 600;
  font-size: 16px;
  background: #0064FB;
  border-radius: 5px;
}

.subscribe-area-4 .subscribe-contact-wrap h4 {
  font-weight: 400;
  font-size: 24px;
  color: #212121;
}

.subscribe-area-4 .subscribe-contact-wrap h2 {
  font-weight: 700;
  font-size: 30px;
  color: #0066FF;
}

@media all and (min-width: 1600px) {
  .right-collapse-img {
    margin-right: -300px;
  }
}

@media all and (max-width: 1199px) {
  .banner-area-4 .banner-inner {
    padding: 160px 0 160px 0;
  }

  .banner-area-4 .banner-inner .title {
    position: relative;
    font-size: 50px;
  }

  .banner-area-4 .banner-inner .title .title-line {
    top: 34px;
  }

  .banner-area-4 .thumb {
    margin-bottom: -50px;
    margin-right: 0;
  }
}

@media all and (max-width: 991px) {
  .banner-area-4 .banner-inner .title {
    display: inline;
  }

  .banner-area-4 .banner-inner .title .title-line {
    top: 44px;
  }

  .banner-area-4 .thumb {
    margin-bottom: 0;
    margin-right: 0;
  }

  .banner-area-4 .banner-inner {
    padding: 160px 0 10px 0;
  }

  .subscribe-area-4 {
    padding: 30px;
  }

  .subscribe-area-4 .single-subscribe-inner-4 {
    padding-right: 30px;
    margin-right: 30px;
  }
}

@media all and (max-width: 575px) {
  .banner-area-4 .banner-inner .title {
    font-size: 30px;
  }

  .banner-area-4 .banner-inner .title .title-line {
    top: 34px;
  }

  .banner-inner .btn-wrap {
    margin-top: 20px;
  }

  .banner-inner .btn-wrap a {
    margin-bottom: 10px;
  }

  .single-pricing-inner.style-4 {
    padding: 32px 20px 0px;
  }

  .single-pricing-inner.style-4 .header h3 {
    font-size: 20px;
    margin: 16px 0;
  }

  .single-pricing-inner.style-4 .header .price h2 {
    font-size: 45px;
    margin-left: -50px;
  }

  .single-pricing-inner.style-4 .details .single-list-inner li {
    font-size: 16px;
    line-height: 33px;
  }

  .single-pricing-inner.style-4 .header .price {
    margin-bottom: 17px;
  }

  .single-pricing-inner.style-4 .details .single-list-inner {
    margin-bottom: 30px;
  }

  .single-pricing-inner.style-4 .details .btn {
    height: 60px;
    line-height: 57px;
    font-size: 18px;
  }

  .subscribe-area-4 .single-subscribe-inner-4 {
    padding-right: 0;
    margin-right: 0;
    border-right: 0;
    margin-bottom: 20px;
  }

  .subscribe-area-4 .single-input-inner {
    display: block;
  }

  .subscribe-area-4 .single-input-inner button {
    width: 100%;
    border-radius: 0;
  }

  .subscribe-area-4 .subscribe-contact-wrap h4 {
    font-size: 16px;
  }

  .subscribe-area-4 .subscribe-contact-wrap h2 {
    font-weight: 600;
    font-size: 16px;
  }

  .footer-gallery-img li {
    width: 25%;
  }

  .footer-gallery-img li img {
    width: 100%;
  }
}

.home-cyber {
  --main-color: #446DFF;
  --heading-font: "Inter", sans-serif;
  --body-font: "Roboto", sans-serif;
}

.home-cyber .custom-container {
  padding: 0 57px 0 115px;
}

.home-cyber .btn-gradient {
  background: linear-gradient(269.19deg, #2949FA 11.26%, #00C9E2 86.6%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  border-radius: 5px;
  font-weight: 600;
  height: 60px;
  line-height: 58px;
  font-size: 20px;
  padding: 0 40px;
}

.home-cyber .btn-gradient:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  /* background: url(../img/home-cyber/button-border.webp); */
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
}

.home-cyber .btn-gradient:hover {
  background: transparent;
  color: #fff;
  -webkit-text-fill-color: #fff;
}

.home-cyber .btn-gradient:after {
  background: #446DFF;
}

.navbar-area-5.sticky-active {
  background: var(--main-color);
}

.navbar-area-5.sticky-active .nav-container {
  padding: 20px 60px;
}

.navbar-area-5 .nav-container {
  padding: 44px 115px;
}

.navbar-area-5 .nav-container .navbar-collapse .navbar-nav {
  margin-left: 145px;
}

.navbar-area-5 .nav-container .navbar-collapse .navbar-nav li a {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  font-family: var(--heading-font);
}

.navbar-area-5 .nav-container .navbar-collapse .navbar-nav li:hover a {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-area-5 .nav-container .navbar-collapse .navbar-nav li:hover.menu-item-has-children:before,
.navbar-area-5 .nav-container .navbar-collapse .navbar-nav li:hover.menu-item-has-children:after {
  background: rgba(255, 255, 255, 0.8);
}

.navbar-area-5 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:before,
.navbar-area-5 .nav-container .navbar-collapse .navbar-nav li.menu-item-has-children:after {
  background: #fff;
}

.navbar-area-5 .nav-right-part .btn {
  height: 60px;
  line-height: 56px;
  border: 2px solid #FFFFFF;
  border-radius: 5px;
  padding: 0 40px;
  font-weight: 600;
  font-size: 20px;
  font-family: var(--heading-font);
}

.navbar-area-5 .nav-right-part-desktop {
  margin-left: 20px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-area-5 .nav-right-part-desktop .nice-select {
  background: transparent;
  border: 0;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  padding-left: 0;
}

.navbar-area-5 .nav-right-part-desktop .nice-select .list {
  width: 100%;
  margin: 0;
  background: var(--main-color);
}

.navbar-area-5 .nav-right-part-desktop .nice-select .list li {
  padding: 0;
  text-align: center;
}

.navbar-area-5 .nav-right-part-desktop .nice-select .option.focus,
.navbar-area-5 .nav-right-part-desktop .nice-select .option.selected.focus,
.navbar-area-5 .nav-right-part-desktop .nice-select .option:hover {
  background: rgba(255, 255, 255, 0.5);
}

.navbar-area-5 .nav-right-part-desktop .nice-select:after {
  border-color: #fff;
  width: 6px;
  height: 6px;
}

.navbar-area-5 .nav-right-part-desktop ul {
  list-style: none;
  padding: 0;
  margin: 0 15px 0 21px;
}

.navbar-area-5 .nav-right-part-desktop ul li {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
}

.banner-area-5 .bg-cover {
  padding: 228px 0 304px;
}

.banner-area-5 .banner-inner h5 {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #fff;
  margin-bottom: 6px;
}

.banner-area-5 .banner-inner h1 {
  font-weight: 600;
  font-size: 68px;
  line-height: 88px;
}

.banner-area-5 .banner-inner .btn-wrap {
  margin-top: 79px;
}

.client-slider-home-5 {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 30px 80px rgba(0, 73, 171, 0.05);
  box-shadow: 0px 30px 80px rgba(0, 73, 171, 0.05);
  border-radius: 20px;
  position: relative;
  padding: 28px 10px 13px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  margin-bottom: -109px;
}

.client-slider-home-5 .client-slider {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 30px 80px rgba(0, 73, 171, 0.05);
  box-shadow: 0px 30px 80px rgba(0, 73, 171, 0.05);
  border-radius: 20px;
  padding: 75px 0;
}

.single-intro-inner.style-five {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 60px 30px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  position: relative;
  z-index: 0;
}

.single-intro-inner.style-five:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(179.21deg, #2949FA -0.86%, #00C9E2 96.68%);
  -webkit-box-shadow: -30px 0px 100px rgba(0, 0, 0, 0.1);
  box-shadow: -30px 0px 100px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: -1;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  opacity: 0;
}

.single-intro-inner.style-five:hover:after {
  opacity: 1;
}

.single-intro-inner.style-five:hover .thumb .img-hover {
  opacity: 1;
}

.single-intro-inner.style-five:hover p,
.single-intro-inner.style-five:hover h4 {
  color: #fff;
}

.single-intro-inner.style-five .thumb {
  position: relative;
  display: inline-block;
}

.single-intro-inner.style-five .thumb img {
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.single-intro-inner.style-five .thumb .img-hover {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  opacity: 0;
}

.single-intro-inner.style-five h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #000;
  margin-bottom: 24px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.single-intro-inner.style-five p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #777777;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  margin-bottom: 0;
}

.about-tab-5 .nav-pills {
  margin-bottom: 60px;
}

.about-tab-5 .nav-pills .nav-item .nav-link {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #000;
  background: transparent;
  padding: 0 0 20px 0;
  position: relative;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.about-tab-5 .nav-pills .nav-item .nav-link.active {
  background: -webkit-gradient(linear, right top, left top, color-stop(19.71%, #2949FA), color-stop(69.71%, #00C9E2));
  background: linear-gradient(270deg, #2949FA 19.71%, #00C9E2 69.71%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-tab-5 .nav-pills .nav-item .nav-link.active:after {
  width: 100%;
}

.about-tab-5 .nav-pills .nav-item .nav-link:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  background: linear-gradient(269.98deg, #2949FA 6.38%, #00C9E2 68.77%);
}

.about-tab-5 .nav-pills .nav-item+.nav-item .nav-link {
  margin-left: 50px;
}

.about-tab-5 .tab-content p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  font-family: var(--heading-font);
  color: #777777;
  margin-bottom: 30px;
}

.about-tab-5 .tab-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.about-tab-5 .tab-content ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  font-family: var(--heading-font);
  color: #777777;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-tab-5 .tab-content ul li+li {
  margin-top: 40px;
}

.about-tab-5 .tab-content ul li .icon {
  background: #E9EEFE;
  border-radius: 5px;
  height: 45px;
  width: 45px;
  display: inline-block;
  text-align: center;
  line-height: 43px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  margin-right: 15px;
}

.single-fact-wrap-5 {
  text-align: center;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 27px 40px 60px;
  margin-bottom: 30px;
}

.single-fact-wrap-5 .thumb {
  display: inline-block;
  position: relative;
}

.single-fact-wrap-5 .thumb h2 {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: 38px;
  color: #fff;
}

.single-fact-wrap-5 h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #222429;
  margin-bottom: 12px;
}

.single-fact-wrap-5 p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #555555;
  margin-bottom: 0;
}

.single-service-inner.style-5 {
  padding: 30px 24px 60px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 30px 60px rgba(19, 45, 73, 0.05);
  box-shadow: 0px 30px 60px rgba(19, 45, 73, 0.05);
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  position: relative;
  z-index: 0;
}

.single-service-inner.style-5:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(179.21deg, #2949FA -0.86%, #00C9E2 96.68%);
  -webkit-box-shadow: -30px 0px 100px rgba(0, 0, 0, 0.1);
  box-shadow: -30px 0px 100px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: -1;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  opacity: 0;
}

.single-service-inner.style-5:hover:after {
  opacity: 1;
}

.single-service-inner.style-5:hover .thumb .icon {
  opacity: 1;
}

.single-service-inner.style-5:hover p,
.single-service-inner.style-5:hover h4 a {
  color: #fff;
}

.single-service-inner.style-5 .thumb {
  position: relative;
  display: inline-block;
}

.single-service-inner.style-5 .thumb .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.single-service-inner.style-5 h4 a {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #071131;
  margin-bottom: 22px;
  margin-top: 22px;
  display: block;
}

.single-service-inner.style-5 p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #777777;
  margin-bottom: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.single-security-wrap {
  background: #FFFFFF;
  -webkit-box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
}

.single-security-wrap .thumb {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  background: linear-gradient(206.01deg, #2949FA 12.47%, #00C9E2 92.48%);
  height: 65px;
  width: 65px;
  border-radius: 50%;
  text-align: center;
  line-height: 62px;
  margin-right: 30px;
}

.single-security-wrap .details h5 {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 14px;
}

.single-security-wrap .details p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.single-security-wrap-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 35px;
}

.single-security-wrap-2 .thumb {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  background: linear-gradient(206.01deg, #2949FA 12.47%, #00C9E2 92.48%);
  height: 65px;
  width: 65px;
  border-radius: 50%;
  text-align: center;
  line-height: 62px;
  margin-right: 30px;
  position: relative;
}

.single-security-wrap-2 .thumb.border-bottom:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 65px;
  height: 106px;
  width: 2px;
  border: 2px dashed #1E6AF4;
  -webkit-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.single-security-wrap-2 .details h5 {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 14px;
}

.single-security-wrap-2 .details p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.half-bg-base-top {
  position: relative;
}

.half-bg-base-top:after {
  content: "";
  position: absolute;
  height: 65%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  /* background: url("/assets/img/bg/11.webp"); */
  background-size: cover;
}

.home-cyber-team {
  background: #fff;
  padding: 40px 40px 0;
  border-radius: 8px;
  -webkit-box-shadow: 0px 10px 30px rgba(0, 73, 171, 0.05);
  box-shadow: 0px 10px 30px rgba(0, 73, 171, 0.05);
}

.home-cyber-team .single-team-inner .thumb img {
  border-radius: 7px;
}

.home-cyber-team .single-team-inner.style-4 {
  border-bottom: 0;
}

.home-cyber-team .single-team-inner .social-media li a {
  background: #212644;
  color: #fff;
}

.home-cyber-team .single-team-inner .social-media li a:hover {
  background: var(--main-color);
}

.single-pricing-inner.style-5 {
  -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  border: 0;
  position: relative;
  width: 100%;
}

.single-pricing-inner.style-5:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 135px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#2949FA), to(#00C9E2));
  background-image: linear-gradient(180deg, #2949FA, #00C9E2);
}

.single-pricing-inner.style-5 .icon {
  position: relative;
  z-index: 2;
}

.single-pricing-inner.style-5 .single-list-inner li {
  padding-left: 0;
}

.single-pricing-inner.style-5 .single-list-inner li:after {
  display: none;
}

.testimonial-slider-3 .thumb img {
  width: auto;
}

.testimonial-slider-3 .single-testimonial-inner .details p {
  font-size: 20px;
}

.single-blog-inner.style-5 {
  -webkit-box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04);
  border-bottom: 0;
  border-radius: 12px;
}

.single-blog-inner.style-5 .details {
  padding: 0 25px;
}

.single-blog-inner.style-5 .details .blog-meta {
  border-bottom: 1px solid rgba(27, 34, 45, 0.05);
  padding-bottom: 17px;
  margin-bottom: 20px;
}

.single-blog-inner.style-5 .details .blog-meta li {
  color: #1B222D;
  line-height: 1;
  border-right: 1px solid #afafaf;
  padding-right: 15px;
}

.single-blog-inner.style-5 .details .blog-meta li:last-child {
  margin-right: 0;
  border: 0;
  padding-right: 0;
}

.single-blog-inner.style-5 .details .read-more-text {
  color: #071131;
}

.single-blog-inner.style-5 .details .read-more-text:after {
  display: none;
}

.single-blog-inner.style-5 .details .read-more-text span {
  height: 8px;
  width: 22px;
  background-image: -webkit-gradient(linear, left top, right top, from(#2949FA), to(#00C9E2));
  background-image: linear-gradient(to right, #2949FA, #00C9E2);
  display: inline-block;
  border-radius: 20px;
  margin-left: 7px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-blog-inner.style-5 .details .read-more-text:hover span {
  width: 35px;
}

.footer-style-4 .widget_nav_menu ul li:after {
  background: #fff !important;
  height: 5px !important;
  width: 5px !important;
  top: 12px !important;
}

.footer-style-4 .widget .widget-title {
  position: relative;
  margin-bottom: 36px;
}

.footer-style-4 .widget .widget-title:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  background: #fff;
  width: 50px;
  height: 2px;
  border-radius: 30px;
}

.widget_gallery ul {
  margin: 0;
  padding: 0;
}

.widget_gallery ul li {
  margin: 4px 3px;
  width: 30%;
  display: inline-block;
}

.widget_gallery ul li img {
  width: 100%;
}

@media all and (max-width: 991px) {
  .navbar-area-5 .nav-container .navbar-collapse .navbar-nav li a {
    color: #333333 !important;
  }

  .banner-area-5 .bg-cover {
    padding: 228px 0 200px;
  }
}

@media all and (max-width: 575px) {
  .navbar-area-5 .nav-container {
    padding: 20px 15px !important;
  }

  .navbar-area-5.sticky-active {
    background: #fff !important;
  }

  .home-cyber .custom-container {
    padding: 0 15px !important;
  }

  .banner-area-5 .banner-inner h5 {
    font-size: 15px;
  }

  .banner-area-5 .banner-inner h1 {
    font-size: 30px !important;
    line-height: 1.2 !important;
    margin-top: 5px;
  }

  .banner-area-5 .banner-inner .btn-wrap {
    margin-top: 27px;
  }

  .about-tab-5 .nav-pills .nav-item .nav-link {
    font-size: 13px;
    padding: 0 0 3px 0;
    margin-bottom: 7px;
  }

  .about-tab-5 .nav-pills .nav-item+.nav-item .nav-link {
    margin-left: 18px;
  }

  .about-tab-5 .nav-pills {
    margin-bottom: 20px;
  }

  .single-security-wrap-2,
  .single-security-wrap {
    display: block;
  }

  .single-security-wrap-2 .thumb,
  .single-security-wrap .thumb {
    margin-bottom: 20px;
  }

  .single-security-wrap-2 .thumb.border-bottom:after {
    display: none;
  }

  .single-testimonial-inner .details p {
    font-size: 15px !important;
  }
}

/*--------------------------------------------------
    ##Footer
---------------------------------------------------*/
.footer-area {
  position: relative;
  padding-top: 115px;
}

.footer-area:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: var(--heading-color);
  z-index: -1;
}

.footer-area .widget {
  position: relative;
  z-index: 2;
  margin-bottom: 70px;
}

.footer-area .widget-title {
  margin-bottom: 20px;
  position: relative;
}

.footer-area .widget_about .thumb {
  margin-bottom: 20px;
}

.footer-area .widget_about .thumb img {
  max-width: 125px;
}

.footer-area .widget_about .details p {
  margin-bottom: 0;
  color: #fff;
}

.footer-area .widget_about .details .social-media {
  margin-top: 26px;
}

.footer-area .widget_about .details .social-media li a:hover {
  background: var(--main-color);
  color: #fff;
}

.footer-area .widget_nav_menu ul {
  margin: 0;
  padding: 0;
}

.footer-area .widget_nav_menu ul li {
  list-style: none;
  margin-bottom: 7px;
  position: relative;
  color: #fff;
  position: relative;
  padding-left: 20px;
}

.footer-area .widget_nav_menu ul li:after {
  content: "";
  position: absolute;
  left: 0;
  top: 9px;
  background: var(--main-color);
  height: 8px;
  width: 8px;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.footer-area .widget_nav_menu ul li:hover:after {
  background: #fff;
}

.footer-area .widget_subscribe .single-input-inner {
  position: relative;
}

.footer-area .widget_subscribe .single-input-inner input {
  padding-right: 40px;
}

.footer-area .widget_subscribe button {
  padding: 0;
  border: 0;
  position: absolute;
  right: 15px;
  top: 10px;
  background: transparent;
  color: var(--main-color);
}

.footer-area .widget_subscribe label {
  color: #fff;
  position: relative;
  padding-left: 22px;
}

.footer-area .widget_subscribe label input {
  position: absolute;
  left: 0;
  top: 7px;
}

.footer-top {
  padding-top: 55px;
  padding-bottom: 90px;
}

.footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 23px 0;
  position: relative;
  z-index: 2;
}

.footer-bottom p {
  margin-bottom: 0;
  color: #fff;
}

/* .marquee {
  animation: marquee 10s linear infinite;
} */

@keyframes marquee {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

.thumb-img img {
  width: 100%;
  /* Atur lebar gambar sesuai container */
  height: 450px;
  /* Tetapkan tinggi tetap */
  object-fit: cover;
  /* Memastikan gambar tidak terdistorsi */
  border-radius: 8px;
  /* Opsional: Untuk sudut melengkung */
}

.single-blog-inner .details .content-preview {
  overflow: hidden;
  white-space: nowrap;
  /* Tampilkan hanya satu baris teks */
  text-overflow: ellipsis;
  /* Tambahkan titik tiga jika teks terlalu panjang */
  display: block;
}

@media (min-width: 768px) {
  .single-blog-inner .details .content-preview {
    white-space: normal;
    /* Untuk perangkat besar, biarkan multi-baris */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Batas maksimum 3 baris teks */
    -webkit-box-orient: vertical;
  }
}

.single-blog-inner .thumb img {
  width: 100%;
  /* Gambar akan memenuhi lebar container */
  height: 200px;
  /* Atur tinggi gambar yang konsisten */
  object-fit: cover;
  /* Gambar dipotong agar sesuai tanpa distorsi */
  border-radius: 10px;
  /* Opsional: menambahkan sudut membulat */
}

.single-team-inner .thumb img {
  width: 100%;
  /* Gambar akan memenuhi lebar container */
  height: 400px;
  /* Atur tinggi gambar yang konsisten */
  object-fit: cover;
  /* Gambar dipotong agar sesuai tanpa distorsi */
  border-radius: 10px;
  /* Opsional: menambahkan sudut membulat */
}

.single-project-inner .thumb img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}