.thumb {
  position: relative;
}

.team-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

.details-wrap {
  padding: 15px;
}

.details-inner {
  position: relative;
}

.team-name {
  font-size: 1.2rem;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  width: 200px;
  margin: 0 auto;
}

.hover-details-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  text-align: left;
}

.single-team-inner:hover .hover-details-wrap {
  opacity: 1;
}

.hover-details-inner {
  color: white;
}

.team-name-hover {
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  margin: 0 auto;
}

.team-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  margin: 10px 0;
}

.team-coordinator,
.team-schedule,
.team-location,
.team-achievements {
  color: white;
  margin: 5px 0;
}

@media (max-width: 992px) {
  .single-team-inner {
    margin: 10px;
  }

  .team-image {
    height: 200px;
  }
}

@media (max-width: 768px) {
  .single-team-inner {
    margin: 5px;
  }

  .team-image {
    height: 200px;
  }
}
