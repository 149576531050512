.card-perpus {
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.card-perpus img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    margin-bottom: 16px;
}

.card-perpus a {
    color: #0060ff;
    text-decoration: underline;
}

.card-perpus a,
.card-perpus a:active,
.card-perpus a:focus,
.card-perpus a:hover {
    outline: none;
    text-decoration: none;
    color: #002147;
}

.card-perpus a:hover {
    color: #0060ff;
}

.card-perpus a {
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
}

.header-perpus {
    width: 100%;
    background-color: #f5f5f5;
    margin-bottom: 2rem;
    font-size:1rem;
}

.header-perpus ul {
    padding: 10px 15px;
    list-style: none;
}

.header-perpus ul>li{
    display: inline-block;
}

.header-perpus ul li,
.header-perpus ul li a {
    font-weight: 500;
    color: #002147;
    text-decoration: none;
}

.header-perpus ul li:nth-child(2) {
    margin-left: 0.75rem;
}
.header-perpus ul li:nth-child(3) {
    display: inline-block;
    margin-left: 0.75rem;
}

.content-perpus {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical;
}

