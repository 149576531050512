/* Navbar Styling */
.navbars {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: transparent;
  transition: background-color 0.3s ease;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbars.scrolled {
  background-color: #003366;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbars-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.navbars-logo {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.navbars-menu {
  display: flex; /* Ensures the menu is always visible on desktop */
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #003366; /* Default background */
  visibility: hidden; /* Initially hidden on mobile */
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s ease;
}

.navbars-menu.active {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.navbars-item {
  margin: 0 20px;
}

.navbars-item a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
}

.navbars-item a:hover {
  color: #d1d1d1;
}

.submenu {
  display: none;
  position: absolute;
  background-color: #003366;
  list-style: none;
  padding: 10px 0;
  z-index: 1;
}

.navbars-item:hover .submenu {
  display: block;
}

.submenu li {
  padding: 5px 20px;
}

.navbars-container .hamburger {
  display: none;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

/* Responsive Styling */
@media screen and (max-width: 992px) {
  .navbars {
    background-color: #003366;
    padding: 10px 15px;
  }

  .navbars-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .navbars-menu {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    background-color: #003366;
    width: 100%;
    max-height: 0;
    overflow: hidden;
  }

  .navbars-menu.active {
    display: flex;
    max-height: 500px;
  }

  .navbars-item {
    margin: 10px 0;
    width: 100%;
  }

  .submenu {
    position: static;
    padding-left: 20px;
  }

  .submenu li {
    padding: 5px 10px;
  }

  .navbars-container .hamburger {
    display: block;
    margin-left: auto;
  }
}

@media screen and (min-width: 993px) {
  /* For Desktop */
  .navbars-menu {
    visibility: visible; /* Make sure it's always visible */
    opacity: 1;
    position: static; /* Menu is not absolute anymore */
    display: flex; /* Menu displayed in row */
    flex-direction: row;
    align-items: center;
    margin-left: 20px; /* Add space between the logo and menu */
    background-color: transparent; /* Remove background on desktop */
  }

  .navbars-item {
    margin: 0 15px;
  }
}
