.container-struktur {
    padding: 20px 0;
    margin-top: 100px;
}

.header-struktur {
    width: 100%;
    background-color: #f5f5f5;
    margin-bottom: 2rem;
    font-size: 1rem;
}

.header-struktur ul {
    padding: 10px 15px;
    list-style: none;
}

.header-struktur ul>li {
    display: inline-block;
}

.header-struktur ul li,
.header-struktur ul li a {
    font-weight: 500;
    color: #002147;
    text-decoration: none;
}

.header-struktur ul li:nth-child(2) {
    margin-left: 1rem;
}

.struktur {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
}

.struktur img {
    transform: translateY(scrollY * 0.5px);
    position: relative;
    width: 350px;
    height: 400px;
    overflow: hidden;
}

@media screen and (max-width: 992px) {
    .container-struktur {
        padding: 0 20px;
        padding-top: 110px;
        margin-top: 0;
    }

    .struktur {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 1rem
    }

}