.container-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

@media (max-width: 992px) {
  .container-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .container-grid {
    grid-template-columns: 1fr;
  }
}

.card.item {
  border: none;
  box-shadow: none;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}

.card.item img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.card.item img:hover {
  transform: scale(1.1);
}

/* .card-body {
  padding: 20px;
  text-align: center;
} */

.card-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 8px;
  transition: color 0.2s;
}

.read-more-link {
  text-decoration: none;
  color: black;
  font-size: 13px;
  font-weight: bold;
  transition: color 0.2s;
}

.arrow-icon {
  margin-left: 3px;
  transition: transform 0.3s ease;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}